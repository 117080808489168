/* Fixes wrong margin on startpage (not logged in) */
.news-tiles {
  margin: 0 0 28px 0;
  .news-tiles__item_color-green {
    .news-tiles__item-body {
      background: #03735e;
      &:hover {
        background: #00614f;
        .news-tiles__item-pointer {
          background: #00614f;
        }
      }
    }
    .news-tiles__item-pointer {
      background: #03735e;
    }
  }
  .news-tiles__item_color-green-r {
    .news-tiles__item-body {
      background: #026429;
      &:hover {
        background: #002D12;
        .news-tiles__item-pointer {
          background: #002D12;
        }
      }
    }
    .news-tiles__item-pointer {
      background: #009e3d;
    }
  }
  .news-tiles__item_color-yellow {
    .news-tiles__item-body {
      background: #687723;
      &:hover {
        background: #5E681C;
        .news-tiles__item-pointer {
          background: #5E681C;
        }
      }
    }
    .news-tiles__item-pointer {
      background: #5E681C;
    }
  }
  .news-tiles__item_color-yellow-r {
    .news-tiles__item-body {
      background: #fc0;
      &:hover {
        background: #f9cb13;
        .news-tiles__item-pointer {
          background: #f9cb13;
        }
      }
    }
    .news-tiles__item-pointer {
      background: #fc0;
    }
  }
  .news-tiles__item_color-red {
    .news-tiles__item-body {
      background: #943947;
      &:hover {
        background: #8b3240;
        .news-tiles__item-pointer {
          background: #8b3240;
        }
      }
    }
    .news-tiles__item-pointer {
      background: #943947;
    }
  }
  .news-tiles__item_color-red-r {
    .news-tiles__item-body {
      background: #c51a1b;
      &:hover {
        background: #cd2021;
        .news-tiles__item-pointer {
          background: #cd2021;
        }
      }
    }
    .news-tiles__item-pointer {
      background: #c51a1b;
    }
  }
  .news-tiles__item_color-blue {
    .news-tiles__item-body {
      background: #66b8d9;
      &:hover {
        background: #70bad8;
        .news-tiles__item-pointer {
          background: #70bad8;
        }
      }
    }
    .news-tiles__item-pointer {
      background: #66b8d9;
    }
  }
  .news-tiles__item_color-blue-r {
    .news-tiles__item-body {
      background: #186E98;
      &:hover {
        background: #10587B;
        .news-tiles__item-pointer {
          background: #10587B;
        }
      }
    }
    .news-tiles__item-pointer {
      background: #186E98;
    }
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .news-tiles__wrp {
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: -28px;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  .news-tiles__item {
    float: left;
    margin: 0 28px 28px 0;
    position: relative;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    // shadow, not overlapping sibling news tiles items
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      z-index: -1;
      box-shadow: $shadow;
    }
    a:hover {
      text-decoration: none;
    }
  }
  .news-tiles__item-body {
    display: block;
    height: 100%;
    overflow: hidden;
    color: #fff;
    text-decoration: none !important;
    position: relative;
    transition: background-color 0.1s ease-in;
    &:active {
      outline: 0;
    }
  }
  .news-tiles__item-pointer {
    position: absolute;
    z-index: 5;
    top: 50%;
    margin-top: -11px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    transition: background-color 0.1s ease-in;
    display: none;
  }
  .news-tiles__item-image {
    overflow: hidden;
    position: absolute;
    img {
      min-width: 100%;
      height: 100%;
      display: block;
      transition: opacity 0.1s ease-in;
      width: 100% !important;
    }
  }
  .news-tiles__item-text {
    height: 100%;
    overflow: hidden;
    padding: 14px 10px;
    position: relative;
    z-index: 8;
  }
  .news-tiles__item-title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    line-height: 1.2;
    font-weight: normal;
    color: #fff;
  }
  .news-tiles__item-more {
    position: absolute;
    bottom: 18px;
    font-size: 0.875em;
    line-height: 1.2;
    display: inline-block;
  }
}
body#uid-9 {
  .news-tiles {
    margin: 0 0 8px 0;
  }
}
.lt-ie9 {
  .news-tiles {
    .news-tiles__item-pointer {
      display: none !important;
    }
  }
}
div.news-tiles__wrp {
  ul {
    li {
      &:before {
        content: "";
      }
    }
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .news-tiles.news-tiles_layout-1 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-3 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-5 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-left: 51%;
      }
      .news-tiles__item-pointer {
        left: 49%;
        margin-left: -9px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        left: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
  }
  .news-tiles.news-tiles_layout-2 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-3 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-left: 51%;
      }
      .news-tiles__item-pointer {
        left: 49%;
        margin-left: -9px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        left: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-5 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
  }
  .news-tiles.news-tiles_layout-3 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-left: 51%;
      }
      .news-tiles__item-pointer {
        left: 49%;
        margin-left: -9px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        left: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-3 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-4 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
  }
  .news-tiles.news-tiles_layout-4 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-3 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-left: 51%;
      }
      .news-tiles__item-pointer {
        left: 49%;
        margin-left: -9px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        left: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) and (min-width: 768px) {
  .news-tiles.news-tiles_layout-1 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-3 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
    .news-tiles__item-5 {
      width: 224px;
      height: 372px;
      .news-tiles__item-body {
        padding-bottom: 186px;
      }
      .news-tiles__item-pointer {
        left: 102px;
        display: block;
      }
      .news-tiles__item-image {
        width: 224px;
        height: 186px;
        left: 0;
        bottom: 0;
      }
    }
  }
  .news-tiles.news-tiles_layout-2 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-3 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 224px;
      height: 372px;
      .news-tiles__item-body {
        padding-bottom: 186px;
      }
      .news-tiles__item-pointer {
        left: 102px;
        display: block;
      }
      .news-tiles__item-image {
        width: 224px;
        height: 186px;
        left: 0;
        bottom: 0;
      }
    }
    .news-tiles__item-5 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
  }
  .news-tiles.news-tiles_layout-3 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 224px;
      height: 372px;
      .news-tiles__item-body {
        padding-bottom: 186px;
      }
      .news-tiles__item-pointer {
        left: 102px;
        display: block;
      }
      .news-tiles__item-image {
        width: 224px;
        height: 186px;
        left: 0;
        bottom: 0;
      }
    }
    .news-tiles__item-3 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
    .news-tiles__item-4 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
  }
  .news-tiles.news-tiles_layout-4 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
    .news-tiles__item-3 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 224px;
      height: 372px;
      .news-tiles__item-body {
        padding-bottom: 186px;
      }
      .news-tiles__item-pointer {
        left: 102px;
        display: block;
      }
      .news-tiles__item-image {
        width: 224px;
        height: 186px;
        left: 0;
        bottom: 0;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .news-tiles.news-tiles_layout-1 {
    .news-tiles__item-4 {
      clear: both;
    }
  }
  .news-tiles.news-tiles_layout-2 {
    .news-tiles__item-2 {
      float: right;
    }
    .news-tiles__item-4 {
      clear: both;
      float: right;
    }
  }
  .news-tiles.news-tiles_layout-3 {
    .news-tiles__item-4 {
      clear: both;
    }
  }
  .news-tiles.news-tiles_layout-4 {
    .news-tiles__item-2 {
      float: right;
    }
    .news-tiles__item-4 {
      clear: both;
      float: right;
    }
  }
}
@media (min-width: 768px) and (max-width: 1023px) and (max-width: 767px) {
  .news-tiles.news-tiles_layout-1 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-3 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
  }
  .news-tiles.news-tiles_layout-2 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-3 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
  }
  .news-tiles.news-tiles_layout-3 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-3 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
  }
  .news-tiles.news-tiles_layout-4 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-3 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) and (min-width: 768px) {
  .news-tiles.news-tiles_layout-1 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
    .news-tiles__item-3 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
  }
  .news-tiles.news-tiles_layout-2 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
    .news-tiles__item-3 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
  }
  .news-tiles.news-tiles_layout-3 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
    .news-tiles__item-3 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
  }
  .news-tiles.news-tiles_layout-4 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
    .news-tiles__item-3 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
  }
  .col-md-9 {
    .news-tiles.news-tiles_layout-1 {
      .news-tiles__item-2 {
        .news-tiles__item-image {
          display: none;
        }
        width: 224px;
        height: 186px;
        margin-bottom: 0;
        .news-tiles__item-body {
          padding-right: 0;
        }
      }
      .news-tiles__item-4 {
        clear: none;
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 767px) {
  .news-tiles.news-tiles_layout-1 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-3 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-5 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
  }
  .news-tiles.news-tiles_layout-2 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-3 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-5 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
  }
  .news-tiles.news-tiles_layout-3 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-left: 51%;
      }
      .news-tiles__item-pointer {
        left: 49%;
        margin-left: -9px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        left: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-3 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
  }
  .news-tiles.news-tiles_layout-4 {
    .news-tiles__item-1 {
      width: 98%;
      height: auto;
      .news-tiles__item-body {
        padding-top: 100px;
      }
      .news-tiles__item-pointer {
        top: 100px;
        left: 50%;
        margin-left: -10px;
        display: block;
      }
      .news-tiles__item-image {
        width: 100%;
        height: auto;
        position: relative;
        text-align: right;
        img {
          min-width: 0;
          max-width: 480px;
          width: 100%;
          height: auto;
          float: right;
        }
      }
      .news-tiles__item-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
      }
    }
    .news-tiles__item-2 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-left: 51%;
      }
      .news-tiles__item-pointer {
        left: 49%;
        margin-left: -9px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        left: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-3 {
      width: 98%;
      height: 148px;
      .news-tiles__item-body {
        padding-right: 51%;
      }
      .news-tiles__item-pointer {
        left: 51%;
        margin-left: -11px;
        display: block;
      }
      .news-tiles__item-image {
        width: 49%;
        top: 0;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
          height: auto;
          min-width: 251px;
          min-height: 186px;
        }
      }
    }
    .news-tiles__item-4 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 48%;
      height: 148px;
      .news-tiles__item-image {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) {
  .news-tiles.news-tiles_layout-1 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-3 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
    .news-tiles__item-5 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
  }
  .news-tiles.news-tiles_layout-2 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-3 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
    .news-tiles__item-5 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
  }
  .news-tiles.news-tiles_layout-3 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 224px;
      height: 372px;
      .news-tiles__item-body {
        padding-bottom: 186px;
      }
      .news-tiles__item-pointer {
        left: 102px;
        display: block;
      }
      .news-tiles__item-image {
        width: 224px;
        height: 186px;
        left: 0;
        bottom: 0;
      }
    }
    .news-tiles__item-3 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-4 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
  }
  .news-tiles.news-tiles_layout-4 {
    .news-tiles__item-1 {
      width: 728px;
      height: 372px;
      .news-tiles__item-body {
        padding-right: 504px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 504px;
        height: 372px;
        top: 0;
        right: 0;
      }
      .news-tiles__item-text {
        padding-top: 36px;
      }
    }
    .news-tiles__item-2 {
      width: 224px;
      height: 372px;
      .news-tiles__item-body {
        padding-bottom: 186px;
      }
      .news-tiles__item-pointer {
        left: 102px;
        display: block;
      }
      .news-tiles__item-image {
        width: 224px;
        height: 186px;
        left: 0;
        bottom: 0;
      }
    }
    .news-tiles__item-3 {
      width: 476px;
      height: 186px;
      .news-tiles__item-body {
        padding-right: 252px;
      }
      .news-tiles__item-pointer {
        left: 213px;
        display: block;
      }
      .news-tiles__item-image {
        width: 252px;
        height: 186px;
        top: 0;
        right: 0;
      }
    }
    .news-tiles__item-4 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
    .news-tiles__item-5 {
      width: 224px;
      height: 186px;
      margin-bottom: 0;
      .news-tiles__item-image {
        display: none;
      }
    }
  }
}
@media (max-width: 767px) {
  .news-tiles.news-tiles_layout-2 {
    .news-tiles__item-2 {
      float: right;
    }
  }
  .news-tiles {
    ul {
      margin-right: -2%;
    }
    .news-tiles__item {
      margin: 0 2% 2% 0;
    }
  }
  .news-tiles.news-tiles_layout-1 {
    .news-tiles__item-4 {
      .news-tiles__item-image {
        img {
          height: 100%;
          min-width: 100%;
          width: auto;
        }
      }
    }
    .news-tiles__item-5 {
      .news-tiles__item-image {
        img {
          height: 100%;
          min-width: 100%;
          width: auto;
        }
      }
    }
    .news-tiles__item-1 {
      width: 98%;
      .news-tiles__item-body {
        padding-right: 0;
        padding-top: 0;
      }
      .news-tiles__item-text {
        width: 48%;
        height: 100%;
      }
      .news-tiles__item-image {
        width: 50%;
        float: right;
        img {
          display: block;
          height: 100%;
          min-width: 100%;
          width: auto;
          float: left;
        }
      }
    }
  }
  .col-md-9 {
    .news-tiles.news-tiles_layout-1 {
      .news-tiles__item-4 {
        width: 48%;
        height: 148px;
        .news-tiles__item-image {
          display: none;
        }
        .news-tiles__item-pointer {
          display: none;
        }
        .news-tiles__item-body {
          padding-right: 0;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .news-tiles.news-tiles_layout-2 {
    .news-tiles__item-1 {
      float: right;
    }
  }
  .news-tiles.news-tiles_layout-3 {
    .news-tiles__item-1 {
      float: right;
    }
    .news-tiles__item-5 {
      .news-tiles__item-image {
        float: left;
      }
    }
  }
  .news-tiles.news-tiles_layout-4 {
    .news-tiles__item-5 {
      .news-tiles__item-image {
        float: left;
      }
    }
  }
}
@media print {
  .news-tiles {
    ul {
      margin: 0 !important;
    }
    .news-tiles__more-link {
      display: none;
    }
    .news-tiles__item {
      position: static !important;
      width: auto !important;
      height: auto !important;
      float: none !important;
      clear: both !important;
      padding: 0 !important;
      margin: 0 0 7px 0 !important;
      page-break-inside: avoid;
    }
    .news-tiles__item-body {
      padding: 0 !important;
    }
    .news-tiles__item-pointer {
      display: none !important;
    }
    .news-tiles__item-image {
      position: static !important;
      display: block !important;
      float: left !important;
      margin: 0 10px 7px 0 !important;
      width: 120px !important;
      height: 90px !important;
      img {
        display: block !important;
        width: 100% !important;
        height: auto !important;
        min-width: 0 !important;
        min-height: 0 !important;
        max-width: auto !important;
        max-height: auto !important;
      }
    }
    .news-tiles__item-text {
      position: static !important;
      width: auto !important;
      height: auto !important;
      float: none !important;
      clear: none !important;
      padding: 0 !important;
      overflow: hidden !important;
    }
    .news-tiles__item-title {
      font-size: 1.2em;
      line-height: 1.2;
    }
    .news-tiles__item-more {
      display: none !important;
    }
  }
}
@media screen and (min-width: 768px) {
  .news-tiles {
    .news-tiles__more-link {
      float: left;
      margin-top: -14px;
    }
    .news-tiles__item-text {
      padding: 18px;
    }
    .news-tiles__item-title {
      font-size: 1.5em;
      line-height: 1.2;
    }
    .news-tiles__item-more {
      font-size: 1em;
      line-height: 1.4;
    }
    .news-tiles__item-image {
      img {
        height: 100% !important;
        width: auto !important;
      }
    }
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .news-tiles {
    .news-tiles__item-title {
      font-size: 1.8em;
    }
  }
}
