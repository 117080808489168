.image {
  margin-top: 3px;
  margin-bottom: 14px;
}

@media screen and (min-width: 768px) {
  .image {
    margin-bottom: 28px;
  }
}

.image-align-none {
  float: none;
}

.image-align-left {
  float: left;
  margin-right: 14px;
}

@media screen and (min-width: 768px) {
  .image-align-left {
    margin-right: 28px;
  }
}

.image-align-right {
  float: right;
  margin-left: 14px;
}

.image-align-center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.image-size-small {
  max-width: 48%;
}

@media screen and (min-width: 768px) {
  .image-size-small {
    max-width: 161px;
  }
}

@media screen and (min-width: 1024px) {
  .image-size-small {
    max-width: 161px;
  }
}

.image-size-medium {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .image-size-medium {
    max-width: 224px;
  }
}

@media screen and (min-width: 1024px) {
  .image-size-medium {
    max-width: 350px;
  }
}

.image-size-large {
  max-width: 100%;
  float: none;
}

@media screen and (min-width: 768px) {
  .image-size-large {
    max-width: 478px;
  }
}

@media screen and (min-width: 1024px) {
  .image-size-large {
    max-width: 728px;
  }
}

.image__zoom {
  position: absolute;
  right: 10px;
  top: auto;
  bottom: 10px;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: -1px 0px 1px rgba(0, 0, 0, 0.8);
  display: none;
}

.image-lightbox .image__zoom {
  display: block;
}

.image__wrp {
  position: relative;
}

.image__src {
  width: 100%;
  height: auto;
}

.image__caption {
  padding-top: 5px;
  font-size: 0.875em;
  line-height: 1.2;
}

.image__caption em {
  font-style: normal;
}

.image-left {
  float: left;
  margin-right: 14px;
  margin-bottom: 14px;
  margin-top: 14px;
}

.image-left:empty {
  display: none;
}

.image-left:has(a:empty) {
  display: none;
}

.image-right {
  float: right;
  margin-left: 14px;
  margin-bottom: 14px;
  margin-top: 14px;
}
