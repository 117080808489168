.toggle-box {
  margin-bottom: 28px;
  position: relative;
}

.toggle-box:after {
  content: "";
  display: table;
  clear: both;
}

.toggle-box__header {
  background: #fff;
  padding: 14px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .toggle-box__header {
    cursor: default;
  }
}

.toggle-box__title {
  color: #003f75;
  font-weight: normal;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .toggle-box__body {
    height: auto !important;
    display: block !important;
    visibility: visible;
  }
}

.toggle-box__footer {
  text-align: left;
  padding-top: 7px;
}

.toggle-box__footer .btn {
  margin-bottom: 4px;
}

.toggle-box__icon {
  position: absolute;
  right: 14px;
  top: 12px;
  font-size: 32px;
  color: #878e8a;
  -webkit-transition: -webkit-transform 200ms;
  -moz-transition: -moz-transform 200ms;
  -o-transition: -o-transform 200ms;
  transition: transform 200ms;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

@media screen and (min-width: 768px) {
  .toggle-box__icon {
    display: none;
  }
}

.toggle-box__body.in + .toggle-box__icon {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
