.was-studieren {
  margin-bottom: 28px;
}

.was-studieren__body {
  padding-bottom: 5px;
  position: relative;
}

.was-studieren__body:after {
  content: "";
  display: table;
  clear: both;
}

.was-studieren__links {
  text-align: right;
}

.was-studieren__links .btn {
  width: 100%;
  text-align: left;
  margin-bottom: 4px;
}

@media screen and (min-width: 768px) {
  .was-studieren__links {
    padding-top: 14px;
  }

  .was-studieren__links .btn {
    width: auto;
    text-align: center;
    margin-bottom: 0;
  }
}

.was-studieren__item {
  position: relative;
  margin-bottom: 7px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media screen and (min-width: 768px) {
  .was-studieren__item {
    width: 224px;
    float: left;
    margin-right: -308px;
    margin-bottom: 0;
  }

  .was-studieren__item.was-studieren__item-2, .was-studieren__item.was-studieren__item-3 {
    position: absolute;
    top: 0;
    z-index: 5;
    -webkit-transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    -webkit-transition-delay: 0;
    -moz-transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 0;
    -o-transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 0;
    transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 0;
  }

  .was-studieren__item.was-studieren__item-2 {
    margin-left: 252px;
  }

  .was-studieren__item.was-studieren__item-3 {
    margin-left: 504px;
  }
}

@media screen and (min-width: 1024px) {
  .was-studieren__item {
    width: 308px;
  }

  .was-studieren__item.was-studieren__item-2 {
    margin-left: 336px;
  }

  .was-studieren__item.was-studieren__item-3 {
    margin-left: 672px;
  }
}

@media print {
  .was-studieren__item {
    width: auto !important;
    float: none !important;
    margin-bottom: 7px;
    page-break-inside: avoid;
  }

  .was-studieren__item:after {
    content: "";
    display: table;
    clear: both;
  }
}

.was-studieren__item-body {
  display: block;
  background: #fff;
  color: #0079AD;
  position: relative;
  text-decoration: none !important;
  -webkit-transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000), color 100ms linear, background-color 100ms linear;
  -webkit-transition-delay: 0, 0, 0;
  -moz-transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 0, color 100ms linear 0, background-color 100ms linear 0;
  -o-transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 0, color 100ms linear 0, background-color 100ms linear 0;
  transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 0, color 100ms linear 0, background-color 100ms linear 0;
  -webkit-box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0);
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0);
  outline: 0 !important;
}

.was-studieren__item-body:after {
  content: "";
  display: table;
  clear: both;
}

.was-studieren__item-body:hover {
  color: #1f80d6;
}

.was-studieren__item.active .was-studieren__item-body {
  background-color: #003f75;
  color: #fff;
}

.was-studieren__item-image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  float: left;
}

.was-studieren__item-image img {
  width: 142px;
  height: 80px;
  margin-left: -31px;
}

@media screen and (min-width: 768px) {
  .was-studieren__item-image {
    width: auto;
    height: auto;
    float: none;
  }

  .was-studieren__item-image img {
    width: 224px;
    height: 126px;
    margin: 0;
  }
}

@media screen and (min-width: 1024px) {
  .was-studieren__item-image img {
    width: 308px;
    height: 173px;
  }
}

@media print {
  .was-studieren__item-image {
    width: 120px;
    height: 90;
    float: left;
    margin-right: 10px;
  }

  .was-studieren__item-image img {
    width: 100%;
    height: auto;
    margin: 0;
  }
}

.was-studieren__item-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.2;
  padding: 0 7px 0 14px;
  margin: 0 36px 0 80px;
  border-right: 2px solid #f3f3f3;
  position: relative;
  height: 80px;
}

.was-studieren__item-title:after {
  display: block;
  right: -36px;
  width: 34px;
  text-align: center;
  top: 50%;
  line-height: 0;
}

.was-studieren__item-title div {
  display: table-cell;
  vertical-align: middle;
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
}

@media screen and (min-width: 768px) {
  .was-studieren__item-title {
    font-size: 1.15em;
    line-height: 1.2;
    margin-left: 0;
    height: auto;
    min-height: 68px;
    padding: 14px 5px 14px 10px;
  }

  .was-studieren__item-title div {
    height: 2.4em;
  }
}

@media screen and (min-width: 1024px) {
  .was-studieren__item-title {
    font-size: 1.2em;
    padding: 14px 7px 14px 14px;
  }
}

@media print {
  .was-studieren__item-title {
    font-size: 1.2em;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    border-right: none;
    position: relative;
    height: auto;
  }

  .was-studieren__item-title:after {
    display: none;
  }

  .was-studieren__item-title div {
    display: block;
    height: auto;
  }
}

.was-studieren__panel-wrp {
  position: relative;
  z-index: 100;
}

@media screen and (min-width: 768px) {
  .was-studieren__panel-wrp {
    -webkit-box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0);
    box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0);
    -webkit-transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    -webkit-transition-delay: 0;
    -moz-transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 0;
    -o-transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 0;
    transition: all 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 0;
    float: right;
    overflow: hidden;
    width: 539px;
  }

  .csstransforms3d .was-studieren__panel-wrp {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@media screen and (min-width: 1024px) {
  .was-studieren__panel-wrp {
    width: 728px;
  }
}

.was-studieren__panel.collapsing {
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.was-studieren__panel.no-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.was-studieren__panel-body {
  padding-bottom: 28px;
}

@media screen and (min-width: 768px) {
  .was-studieren__panel-body {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .was-studieren_show-panel .was-studieren__item-2 {
    left: -189px;
  }

  .was-studieren_show-panel .was-studieren__item-3 {
    left: -378px;
  }

  .was-studieren_show-panel .was-studieren__panel-wrp {
    margin-left: -100%;
  }

  .csstransforms3d .was-studieren_show-panel .was-studieren__item-2 {
    -webkit-transform: translate3d(-189px, 0, 0);
    -moz-transform: translate3d(-189px, 0, 0);
    -ms-transform: translate3d(-189px, 0, 0);
    -o-transform: translate3d(-189px, 0, 0);
    transform: translate3d(-189px, 0, 0);
    left: auto;
  }

  .csstransforms3d .was-studieren_show-panel .was-studieren__item-3 {
    -webkit-transform: translate3d(-378px, 0, 0);
    -moz-transform: translate3d(-378px, 0, 0);
    -ms-transform: translate3d(-378px, 0, 0);
    -o-transform: translate3d(-378px, 0, 0);
    transform: translate3d(-378px, 0, 0);
    left: auto;
  }

  .csstransforms3d .was-studieren_show-panel .was-studieren__panel-wrp {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    margin-left: 0;
  }

  .was-studieren_show-panel .was-studieren__item-2, .was-studieren_show-panel .was-studieren__item-3 {
    -webkit-transition-delay: 500ms;
    -moz-transition-delay: 500ms;
    -o-transition-delay: 500ms;
    transition-delay: 500ms;
  }

  .was-studieren_show-panel .was-studieren__item-2 .was-studieren__item-body, .was-studieren_show-panel .was-studieren__item-3 .was-studieren__item-body {
    -webkit-box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition-delay: 500ms, 0, 0;
    -moz-transition-delay: 500ms, 0, 0;
    -o-transition-delay: 500ms, 0, 0;
    transition-delay: 500ms, 0, 0;
    filter: progid:DXImageTransform.Microsoft.Shadow(color='#bbbbbb', Direction=270, Strength=5);
  }

  .was-studieren_show-panel .was-studieren__panel-wrp {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition-delay: 500ms;
    -moz-transition-delay: 500ms;
    -o-transition-delay: 500ms;
    transition-delay: 500ms;
    filter: progid:DXImageTransform.Microsoft.Shadow(color='#bbbbbb', Direction=270, Strength=5);
  }
}

@media screen and (min-width: 1024px) {
  .was-studieren_show-panel .was-studieren__item-2 {
    left: -252px;
  }

  .was-studieren_show-panel .was-studieren__item-3 {
    left: -504px;
  }

  .csstransforms3d .was-studieren_show-panel .was-studieren__item-2 {
    -webkit-transform: translate3d(-252px, 0, 0);
    -moz-transform: translate3d(-252px, 0, 0);
    -ms-transform: translate3d(-252px, 0, 0);
    -o-transform: translate3d(-252px, 0, 0);
    transform: translate3d(-252px, 0, 0);
    left: auto;
  }

  .csstransforms3d .was-studieren_show-panel .was-studieren__item-3 {
    -webkit-transform: translate3d(-504px, 0, 0);
    -moz-transform: translate3d(-504px, 0, 0);
    -ms-transform: translate3d(-504px, 0, 0);
    -o-transform: translate3d(-504px, 0, 0);
    transform: translate3d(-504px, 0, 0);
    left: auto;
  }
}

@media screen and (min-width: 768px) {
  .was-studieren__panel-wrp .nav-tabs {
    background: #f3f3f3;
    height: 33px;
  }

  .was-studieren__panel-wrp .tabs.nav-tabs, .was-studieren__panel-wrp .tabs.nav-tabs > li.active > a, .was-studieren__panel-wrp .tabs.nav-tabs > li.active > a:active {
    border-color: transparent !important;
  }
}

.was-studieren__panel-wrp .tab-content {
  background: #fff;
  position: relative;
}

@media screen and (min-width: 768px) {
  .was-studieren__panel-wrp .tab-content {
    min-height: 161px;
  }
}

@media screen and (min-width: 1024px) {
  .was-studieren__panel-wrp .tab-content {
    min-height: 208px;
  }
}

.was-studieren__panel-wrp .tab-content .tab-pane {
  padding: 20px 28px;
}

.was-studieren__tab-pane-content {
  display: none;
}

.was-studieren__tab-pane-content-backlink {
  margin-bottom: 14px;
}

.was-studieren__item-title {
  border-top: 2px solid #e9eaed;
}

@media screen and (min-width: 768px) {
  .was-studieren__panel-wrp .nav-tabs {
    background: #e9eaed;
  }
}

.was-studieren__tab-pane-content .col-sm-6 {
  width: 68%;
  z-index: 9999;
}

.was-studieren__tab-pane-content .col-sm-6 iframe {
  z-index: 9999;
}
