.timetablebox .toggle-box__footer {
  text-align: left;
}

.timetablebox__body {
  position: relative;
  margin: -14px;
}

.timetablebox__body ul {
  padding-left: 0;
}

.timetablebox__pager {
  display: block;
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #a1a7a4;
  text-decoration: none !important;
  cursor: pointer;
}

.timetablebox__pager:hover, .timetablebox__pager:focus {
  color: #1f80d6;
}

.timetablebox__pager i {
  font-size: 24px;
  line-height: 40px;
}

.timetablebox__pager span {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.timetablebox__pager_state-disabled {
  color: #ddd !important;
  cursor: default;
}

.timetablebox__rewind {
  left: 0;
  border-right: 2px solid #f3f3f3;
}

.timetablebox__forward {
  right: 0;
  border-left: 2px solid #f3f3f3;
}

.timetablebox__items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.timetablebox__item {
  display: none;
}

.timetablebox__item.timetablebox__item_state-active {
  display: block;
}

.timetablebox__item-date {
  height: 40px;
  overflow: hidden;
  line-height: 40px;
  background: #fff;
  border-bottom: 1px solid #e9eaed;
  white-space: nowrap;
  text-align: center;
}

.timetablebox__entries {
  list-style: none;
  margin: 0;
  padding: 0;
}

.timetablebox__entry {
  margin-top: 2px;
  background: #fff;
  line-height: 1.2;
}

.timetablebox__entry a {
  display: block;
  color: #1d1d1b;
  text-decoration: none !important;
  padding: 10px 14px;
}

.timetablebox__entry a:hover, .timetablebox__entry a:focus {
  color: #1f80d6;
}

@media screen and (min-width: 1024px) {
  .timetablebox__entry-date {
    float: left;
    width: 60px;
  }
}

.timetablebox__entry-title {
  overflow: hidden;
}

.timetablebox__entry-title em {
  display: block;
  font-style: normal;
  color: #878e8a;
  padding-top: 3px;
}
