//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  margin-bottom: 0;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;

  > li {
    display: inline-block;
      font-size: 0.9em;

      a {
          color: $gray-darker;
      }

    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      content: "#{$breadcrumb-separator}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
      color: $breadcrumb-color;
      font-family: 'FontAwesome';
      padding: 0 4px 0 4px;
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }
}
