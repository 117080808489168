.spinner {
  font-family: sans-serif;
  font-weight: 100;
}

.spinner:not(:required) {
  -webkit-animation: spinner 1000ms infinite linear;
  -moz-animation: spinner 1000ms infinite linear;
  -ms-animation: spinner 1000ms infinite linear;
  -o-animation: spinner 1000ms infinite linear;
  animation: spinner 1000ms infinite linear;
  border: 4px solid #38e;
  border-right-color: transparent;
  border-radius: 32px;
  box-sizing: border-box;
  display: none;
  position: relative;
  overflow: hidden;
  text-indent: -9999px;
  width: 64px;
  height: 64px;
}

.spinner_pos-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  z-index: 200;
}

.spinner_state-active {
  display: inline-block !important;
}

.spinner__cover {
  background-color: rgba(255, 255, 255, 0);
  display: block;
  height: 0;
  -webkit-transition: background-color 120ms linear;
  -webkit-transition-delay: 500ms;
  -moz-transition: background-color 120ms linear 500ms;
  -o-transition: background-color 120ms linear 500ms;
  transition: background-color 120ms linear 500ms;
  width: 0;
}

.spinner_state-active + .spinner__cover {
  background-color: rgba(255, 255, 255, 0.8);
}

.spinner_state-active.spinner_pos-center + .spinner__cover {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
