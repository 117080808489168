.slider {
  position: relative;
  white-space: nowrap;
}

.slider__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.csstransforms3d .slider__list, .csstransforms .slider__list {
  -webkit-transition: -webkit-transform 300ms ease-out;
  -moz-transition: -moz-transform 300ms ease-out;
  -o-transition: -o-transform 300ms ease-out;
  transition: transform 300ms ease-out;
}

.slider_state-dragging .slider__list {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.slider__item {
  display: inline-block;
  vertical-align: top;
}

.slider__pager {
  -webkit-transition: opacity 120ms;
  -moz-transition: opacity 120ms;
  -o-transition: opacity 120ms;
  transition: opacity 120ms;
}

.slider__pager i {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  line-height: 1;
}

.slider__pager span {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.slider__prev {
  float: left;
}

.slider__next {
  float: right;
}

.slider__prev_state-inactive, .slider__next_state-inactive {
  cursor: default;
  opacity: 0.3;
}

.news-ticker__title {
  font-family: Arial, Helvetica, sans-serif;
  color: #003f75;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.news-ticker__title {
  font-size: 1.4em;
  line-height: 1.2;
}

.onlinekurier__header {
  margin-bottom: 28px;
}

.onlinekurier__header h1, .onlinekurier__header h2 {
  margin-bottom: 14px;
}

@media screen and (min-width: 768px) {
  .onlinekurier__header {
    display: table;
    width: 100%;
    margin-bottom: 42px;
  }

  .onlinekurier__header h1, .onlinekurier__header h2 {
    margin: 0;
  }

  .onlinekurier__title, .onlinekurier__select {
    display: table-cell;
    vertical-align: bottom;
  }

  .onlinekurier__select {
    text-align: right;
    width: 1%;
    padding-left: 28px;
  }

  .onlinekurier__select > .btn-group {
    text-align: left;
  }

  .onlinekurier__select > .btn-group .btn-group {
    margin-bottom: 4px;
  }
}

@media screen and (min-width: 1024px) {
  .onlinekurier__select {
    width: auto;
  }
}

.news-teaser {
  margin-bottom: 28px;
}

.news-teaser:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 768px) {
  .news-teaser {
    margin-bottom: 0;
  }
}

@media print {
  .news-teaser {
    margin-bottom: 28px;
  }
}

.news-teaser__title small {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.55em;
  padding-top: 0.3em;
}

.news-teaser__item-group {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.news-teaser__item {
  margin-bottom: 7px;
}

@media screen and (min-width: 768px) {
  .news-teaser__item {
    margin-bottom: 28px;
  }

  .news-teaser__item:nth-child(4n+1) {
    clear: both;
  }
}

@media print {
  .news-teaser__item {
    width: auto !important;
    float: none !important;
    margin-bottom: 7px;
    page-break-inside: avoid;
  }

  .news-teaser__item:after {
    content: "";
    display: table;
    clear: both;
  }
}

.news-teaser__item-body {
  display: block;
  height: 80px;
  background: #fff;
  color: #0079AD;
  position: relative;
  text-decoration: none !important;
  overflow: hidden;
}

.news-teaser__item-body:after {
  content: "";
  display: table;
  clear: both;
}

.news-teaser__item-body:hover {
  color: #1f80d6;
}

@media screen and (min-width: 768px) {
  .news-teaser__item-body {
    height: 100%;
  }
}

@media print {
  .news-teaser__item-body {
    height: auto !important;
  }
}

.news-teaser__item-image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  float: left;
}

.news-teaser__item-image img {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 0 0 -31px;
}

@media screen and (min-width: 768px) {
  .news-teaser__item-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 91px;
    width: auto;
    float: none;
    margin: 0 -100px;
  }

  .news-teaser__item-image img {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .news-teaser__item-image {
    height: 128px;
  }
}

@media print {
  .news-teaser__item-image {
    position: static;
    width: 120px;
    height: 90px;
    float: left;
    margin-right: 10px;
  }

  .news-teaser__item-image img {
    width: 100%;
    height: auto;
    margin: 0;
  }
}

.news-teaser__item-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.2;
  padding: 0 7px 0 14px;
  margin: 0 36px 0 80px;
  position: relative;
  height: 80px;
}

.news-teaser__item-title:after {
  display: block;
  right: -36px;
  width: 34px;
  text-align: center;
  top: 50%;
  line-height: 0;
}

.news-teaser__item-title div {
  display: table-cell;
  vertical-align: middle;
  height: 80px;
}

@media (max-width: 767px) {
  .news-teaser__item-title div {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
  }
}

@media screen and (min-width: 768px) {
  .news-teaser__item-title {
    display: table;
    width: 100%;
    height: 100%;
    font-size: 0.875em;
    line-height: 1.2;
    margin-left: 0;
    padding: 91px 0 0 0;
    border: 0;
  }

  .news-teaser__item-title:after {
    position: static;
    width: auto;
    display: table-cell;
    vertical-align: middle;
    padding: 0 7px;
  }

  .news-teaser__item-title div {
    width: 100%;
    height: auto;
    padding: 7px 4px 7px 10px;
    border-right: 2px solid #f3f3f3;
  }
}

@media screen and (min-width: 1024px) {
  .news-teaser__item-title {
    font-size: 1em;
    line-height: 1.4;
    padding: 126px 0 0 0;
  }

  .news-teaser__item-title:after {
    padding: 0 10px;
  }

  .news-teaser__item-title div {
    padding: 14px 7px 14px 14px;
  }
}

@media print {
  .news-teaser__item-title {
    font-size: 1.2em;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    border-right: none;
    position: relative;
    display: block;
    height: auto;
  }

  .news-teaser__item-title:after {
    display: none;
  }

  .news-teaser__item-title div {
    display: block;
    height: auto;
  }
}

.news-ticker {
  margin-bottom: 28px;
}

.col-sidebar > .news-ticker {
  font-size: 0.9em;
}

.news-ticker__title {
  margin-bottom: 14px;
}

.news-ticker__item-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.news-ticker__item {
  border-bottom: 1px solid #b2c5d6;
  padding-bottom: 10px;
  margin: 0 0 10px 0;
}

.news-ticker__item > a {
  display: inline-block;
}

.news-ticker__item small {
  display: block;
  padding-top: 0.2em;
}

.news-ticker__item-relation {
  padding-left: 4px;
}

.news-list {
  margin-bottom: 28px;
}

@media screen and (min-width: 768px) {
  .news-list {
    margin-bottom: 0;
  }
}

.news-list__item-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.news-list__item {
  margin-bottom: 14px;
}

.news-list__item:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 768px) {
  .news-list__item {
    margin-bottom: 28px;
  }
}

.news-list__item-image {
  width: 80px;
  float: left;
  overflow: hidden;
  margin: 2px 14px 14px 0;
}

.news-list__item-image img {
  width: auto;
  height: 80px;
  margin-left: -31px;
}

@media screen and (min-width: 768px) {
  .news-list__item-image {
    width: 161px;
  }

  .news-list__item-image img {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
}

@media screen and (min-width: 1024px) {
  .news-list__item-image {
    width: 224px;
  }
}

.news-list__item-text {
  overflow: hidden;
}

.news-list__item-title {
  margin: 0 0 0.5em 0;
  line-height: 0;
}

.news-list__item-title small, .news-list__item-title em {
  display: inline-block;
  line-height: 1.2;
  font-style: normal;
}

.news-list__item-title small {
  font-weight: bold;
  color: #666;
  margin-bottom: 3px;
}

@media (max-width: 767px) {
  .news-list__item-title {
    font-size: 1em;
    line-height: 0;
  }
}

.newsbox .toggle-box__body {
  padding-top: 2px;
}

.sub1col.nzg_relatedtags {
  width: 30%;
  float: left;
}

.sub1col.nzg_relatedtags + .sub1col.nzg_relatedtags {
  margin-left: 4.8%;
}

.sub1col.nzg_relatedtags h2 a {
  color: #036 !important;
  font-size: 0.8em;
}

.sub3col.nzg_tags {
  clear: left;
}

.teaser-group__item-text {
  padding: 14px;
}

.timetablebox__forward {
  border-left: 2px solid #e9eaed;
}

.tradingbox__item + .tradingbox__item {
  border-top: 2px solid #e9eaed;
}

@media screen and (min-width: 1024px) {
  .navigation-main-flyout-cover-head {
    background: #e9eaed;
  }
}

@media screen and (min-width: 768px) {
  .news-teaser__item-title div {
    border-right: 2px solid #e9eaed;
  }
}

span.tx_newsrubrics_subtitle {
  font-size: 1.0em !important;
}

.newscontainer-right,
.newscontainer-left {
  background-color: #d3e3f0;
  padding: 10px 10px 5px 10px;
  overflow: hidden;
}

.news-list-browse .browseLinksWrap,
ul.tx-pagebrowse {
  border-radius: 4px;
  display: inline-block;
  margin: 20px 0;
  padding-left: 0;
}

.col-sm-9 .tx-comments-pi1 {
  margin-left: -14px;
}

.tx-comments-comment-single {
  margin-bottom: 14px;
}

.tx-comments-comment-form a {
  color: #0079AD !important;
}

.news-ticker__title {
  margin: 20px 0 10px;
}

#content .teaser-group-honk {
  padding: 30px 14px 0;
}

#content .teaser-group-honk .teaser-group__headline {
  margin-left: -14px;
}

#content .teaser-group-honk .teaser-group__item {
  width: 32%;
}

@media (max-width: 767px) {
  #content .teaser-group-honk .teaser-group__item {
    width: 100%;
  }
  .teaser-group__item-image {
    width: auto;
  }
  #content .teaser-group-honk .teaser-group__item+.teaser-group__item {
    margin-left: 0 !important;
  }
}

#content .teaser-group-honk .teaser-group__item + .teaser-group__item {
  margin-left: 2%;
}

#honk-printpagelink {
  float: right;
  position: relative;
  top: -30px;
}

.tx-comments-comment-form .form-group > div {
  padding-top: 5px;
}

ul.news-teaser__item-group li:before,
ul.news-list__item-group li:before {
  content: "";
}

.news-list-browse .browseLinksWrap a,
.news-list-browse .browseLinksWrap span {
  background-color: #fff !important;
  border: 1px solid #ddd !important;
  float: left;
  margin-left: -1px;
  padding: 10px 12px 10px;
  position: relative;
  text-decoration: none;
}

.newscontainer-left {
  float: left;
  margin-right: 14px;
  margin-bottom: 14px;
  margin-top: 14px;
}

.newscontainer-right {
  float: right;
  margin-left: 14px;
  margin-bottom: 14px;
  margin-top: 14px;
}

.comments-slider__pagers {
  padding-top: 10px;
}

.comments-slider__pagers:after {
  content: "";
  display: table;
  clear: both;
}

.comments-slider {
  margin-bottom: 28px;
  width: 100%;
  overflow: hidden;
}

.comments-slider__content {
  overflow: hidden;
}

@media (max-width: 767px) {
  .comments-slider__list {
    width: 100%;
  }
}

.comments-slider__item {
  position: relative;
  white-space: normal;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .comments-slider__item {
    width: 224px;
  }

  .comments-slider__item-content {
    height: 128px;
  }

  .comments-slider__item + .comments-slider__item {
    margin-left: 28px;
  }
}

.comments-slider__item-content {
  background: #fff;
  position: relative;
}

.comments-slider__item-content:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -40px;
  right: -20px;
  border: 20px solid transparent;
  border-top-color: #fff;
}

.comments-slider__item-title {
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
  padding: 14px 10px 0 14px;
}

.comments-slider__item-text {
  margin: 0;
  padding: 5px 10px 14px 14px;
}

.comments-slider__item-cite {
  display: block;
  color: #999;
  font-size: 0.875em;
  line-height: 1.2;
  padding: 7px 10px 0 0;
}
