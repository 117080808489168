.login-form {
  padding: 14px 0;
  background: #3670a1;
}

.login-form:after {
  content: "";
  display: table;
  clear: both;
}

.login-form label {
  color: #fff;
}

.login-form .checkbox {
  margin: 6px 0 5px 0;
  padding-left: 0;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .login-form .checkbox {
    margin-bottom: -2px;
    margin-top: -4px;
  }
}

@media (max-width: 768px) {
  .login-form .checkbox {
    padding-left: 14px;
  }

  .login-form > form > .col-sm-4:first-child {
    margin-bottom: 5px;
  }

  .user-info button span {
    display: none;
  }

  .user-info button i {
    display: block;
    font-size: 1.5em;
  }

  .keepMeLoggedIn {
    margin-left: 0;
    margin-right: 6px;
  }
}

@media (max-width: 1023px) {
  .login-form .checkbox label {
    line-height: 20px;
  }
}
