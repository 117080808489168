table.hiscalendar {
  table {
    th,
    td {
      border: 1px solid #aaa;
    }
    th {
      background-color: #eee;
    }
    td.month {
      background-color: #7F99B2;
      color: #fff;
      font-weight: bold;
    }
    .termWeekendDay,
    .weekend {
      background-color: #E5EAEE;
    }
  }
}

/* LSF */

/* LSF-Content comes too wide, we can't change it (LSF-Topic). So we need to blow up the layout.  */
.tx-dmcbrowsesoap-pi1-lsfAdapter {
  width: 1340px;
  background-color: #fff;
  padding: 15px;

  .unsichtbar {
    display: none;
  }

  form {
    overflow: visible;
  }

  .pdfplan {
    .th {
      text-align: right;
      border: 0.5pt none;
      font-size: 110%;
      font-weight: bold;
    }

    .td {
      border: 0.5pt solid #014A7B;
      font-size: 110%;
    }
  }

  ul li:before {
    content: "";
  }

  input[type="submit"] {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    margin: 10px 10px 10px 0;
    color: #fff;
    background-color: #036;
    border: none;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: #034985;
      border-color: #285e8e;
      text-decoration: none;
    }
  }
}

.tx-dmcbrowsesoap-pi1 {
  table  {
    h1 {
      font-family: Arial, Helvetica, sans-serif;
      color: #003f75;
      font-weight: bold;
      font-size: 1.5em;
      line-height: 1.2;
      margin: 20px 0 10px;
    }
  }

  #rightColumn {
    margin-top: 0;
  }

  img {
    margin-right: 0.8em !important;
  }

  .plan5 {
    background: #a7c6e2;
  }

  .plan6 {
    background: #d3e3f0;
  }

  td.mod_n_odd {
    padding: 15px;
  }
}

.tx-dmcbrowsesoap-pi1-browsebox {
  table {
    border-radius: 4px;
    display: inline-block;
    margin: 20px 0;
    padding-left: 0;

    td:first-child > a,
    td:first-child > span {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      margin-left: 0;
    }

    td:last-child > a,
    td:last-child > span {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    td:hover {
      background-color: #eee;
    }

    td.active {
      background-color: #428bca;
      border-color: #428bca;
      color: #fff;
      cursor: default;
      z-index: 2;
    }
  }
}

.layout-context .tx-dmcbrowsesoap-pi1-lsfAdapter {
  width: 90%;
}

.kleinesPadding {
  margin-top: 15px;
}

.kleinesPadding td a,
.kleinesPadding td font {
  padding: 5px;
}

.kleinesPadding .nav:before,
.kleinesPadding .nav:after {
  display: inline;
}

.plan_rahmen, .plan1, .plan2 {
  border: 1px solid #003366;
}

.plan5 {
  background-color: #7F99B2;
}

.plan5 A:hover, .plan5 A:visited {
  color: #003366;
}

.plan6 {
  background-color: #E5EAEE;
}

@media screen and (min-width: 768px) {
  .tx-dmcbrowsesoap-pi1 {
    table {
      h1 {
        font-size: 1.8em;
      }
    }
  }
}

#lsfToken {
  display: block;
}
