/* Lists */
ul,
ol {
  list-style-type: none;
  margin-left: 0;
  padding-left: 19px;
}

ol {
  list-style: decimal;
}

/* Lists in text boxes */
ul.bgHell,
ul.bgRot,
ol.bgHell,
ol.bgRot {
  padding-left: 30px;
}

.linklist li:before {
  color: #0079AD;
}

ul.checked {
  padding-left: 30px;

  li {
    padding-top: 8px;

    &:before {
      color: #003f75;
      font-family: 'FontAwesome';
      content: '\f05d';
      font-size: 1.7em;
      margin: -6px -30px;
      font-weight: normal;
    }
  }
}

/* Use this to hide '>' in lists */
ul.hide-listbullets li:before {
  content: "";
}

/* Links */
.more-link:after,
.more-link-small:after,
.toggle-more:after,
.toggle-less:after {
  content: "\f105";
  font-family: 'FontAwesome';
  font-style: normal;
  position: absolute;
  margin-left: 8px;
}

/* Header */
.navigation-main {
  margin-bottom: 14px;
}

.nav-section i.fa {
  color: #fff;
  font-size: 2em;
  margin-top: -0.5em;
  position: absolute;
  right: 14px;
  top: 50%;
  transition: transform 210ms ease 0s;
}

.nav-section a i.fa {
  color: #fff;
  font-size: 2em;
  margin-top: -0.5em;
  position: absolute;
  right: 14px;
  top: 50%;
  transition: transform 210ms;
}

.nav-section a.mobile-toggle_state-active i.fa {
  transform: rotate(-180deg);
}

/*
 * Panels
 *
 * @todo Move this to Bootstrap's build process
 */
.panel-homepage {
  border: none;
  box-shadow: none;
}

.panel-default > .panel-heading-homepage {
  background-color: #fff;
}

.panel-heading-homepage > .panel-title {
  font-size: 1.4em;
  font-weight: normal;
  color: #0079AD;
}

.panel-footer-homepage {
  background-color: #e9eaed;
  padding: 14px 0;
}

/* Links within level-2 shall be directly clickable if they don't contain links in a sublevel and therefore need no Tacken */
.nav-section__level-2.bstrdchilds a i:before {
  content: "";
}

@media screen and (min-width: 1024px) {
  .nav-section a i.fa {
    display: none;
  }
}

/* Slider arrows left/right to show more content */
/* Used for the latest comments slider at the Intranet start page e.g. */
.slider__next i:before,
.timetablebox__forward i:before {
  content: "\f054";
  font-family: 'FontAwesome';
  display: block;
  font-size: 0.8em;
  font-style: normal;
}

.slider__prev i:before,
.timetablebox__rewind i:before {
  content: "\f053";
  font-family: 'FontAwesome';
  display: block;
  font-size: 0.8em;
  font-style: normal;
}

/* Footer */

/* Hides the text Universität Hohenheim in the footer logo */
.link-to-startpage {
  display: block;
  height: 100%;
  text-indent: -9999px;
}

/* Adds > to the links in the footer */
nav.footer-site__navigation ul li::before {
  color: #fff;
  content: "";
  font-family: FontAwesome;
  margin-left: 0;
  display: block;
}

/* Main Navigation */

@media screen and (max-width: 1024px) {
  li.nav-section.nav-section__level-1.spacer {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .navigation-main__section > ul > li {
    float: left;
    height: 54px;
  }
}

/* Special workarounds because of the missing bootstrap capabilities
 * of TYPO3's form creator (content element) */
#mailform legend {
  display: none;
}

#mailform div.radio {
  padding-left: 20px;
}

#mailform div.radio fieldset label {
  display: block;
  padding-left: 0;
}

/*
 * Sidebar
 */

/* Hint: the class .sidebar-teaser__body is deprecated and will be
 * removed soon! It's only for backwards compatibility. */

.col-sidebar {
  margin-top: 12px;
  padding-bottom: 28px;
}

.sidebar {
  box-shadow: $shadow;
  background-color: #c4c6cc;
  .remove-margin {
    margin-bottom: -10px;
    padding-bottom: 0;
  }
}

.sidebar-content,
.sidebar-teaser__body {
  background: #d3e3f0;
  padding: 10px 14px;
  margin-bottom: 3px;
  overflow: hidden;
}

.remove-margin {
  margin-bottom: 0;
}

.sidebar-content h1,
.sidebar-content h2,
.sidebar-content h3,
.sidebar-content h5,
.sidebar-content h6,
.sidebar-teaser__body h1,
.sidebar-teaser__body h2,
.sidebar-teaser__body h3,
.sidebar-teaser__body h5,
.sidebar-teaser__body h6 {
  color: #1D1D1B;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 0.4em;
  margin-top: 0.6em;
}

/* Special for full width images in sidebar */
.sidebar-content .csc-textpic .csc-textpic-imagewrap figure,
.sidebar-teaser__body .csc-textpic .csc-textpic-imagewrap figure {
  margin: -10px -14px 0;
}

/* Workaround for smaller images with position "in text left" */
.sidebar-content .csc-textpic-intext-left .csc-textpic-imagewrap figure {
  margin: 0 0 0 -14px;
}

/* Workaround for jQuery UI datepicker which is overwritten by bootstrap */
.ui-datepicker-header select {
  color: #000;
}

/* Workaround for nicer debug output */
.Extbase-Utility-Debugger-VarDump-Center pre {
  padding: 0;
}

/* download links - start */
a.download:before,
a[href$=".pdf"]:before,
a[href$=".PDF"]:before,
a[href$=".txt"]:before,
a[href$=".TXT"]:before,
a[href$=".EPS"]:before,
a[href$=".eps"]:before,
a[href$=".tif"]:before,
a[href$=".TIF"]:before,
a[href$=".doc"]:before,
a[href$=".DOC"]:before,
a[href$=".docx"]:before,
a[href$=".DOCX"]:before,
a[href$=".dotx"]:before,
a[href$=".DOTX"]:before,
a[href$=".docm"]:before,
a[href$=".DOCM"]:before,
a[href$=".ppt"]:before,
a[href$=".PPT"]:before,
a[href$=".pptx"]:before,
a[href$=".PPTX"]:before,
a[href$=".pptm"]:before,
a[href$=".PPTM"]:before,
a[href$=".xls"]:before,
a[href$=".XLS"]:before,
a[href$=".xlsx"]:before,
a[href$=".XLSX"]:before,
a[href$=".xlsm"]:before,
a[href$=".XLSM"]:before,
a[href$=".zip"]:before,
a[href$=".ZIP"]:before {
  content: '\f019';
  font-family: 'FontAwesome';
  padding-right: 0.4em;
}

/* The download-link-list class replaces the '>' with the download icon from fontawesome */
ul.download-link-list {
  padding-left: 0;
}

ul.download-link-list li:before {
  content: "";
}

ul.download-link-list li a {
  padding-left: 19px;
  display: block;
}
ul.download-link-list li a::before {
  margin-left: -19px;
}

/* download links - end */

/* Special print CSS */
@media print {
  .btn {
    display: none !important;
  }

  .breadcrumb a[href]:after {
    content: "";
  }

  a[href]:after {
    content: "";
  }

  abbr[title]:after {
    content: "";
  }

  .collapse {
    display: block !important;
  }

  .tab-content > .tab-pane {
    visibility: visible !important;
    display: block !important;
  }
}

.page {
  min-width: 320px;
}

.col-sidebar {
  margin-bottom: 28px;
}

.col-main {
  margin-bottom: 28px;
}

@media screen and (min-width: 768px) {
  .sidebar__content-wrp {
    height: auto !important;
    display: block !important;
  }
}

.content-main {
  padding-top: 20px;
  padding-bottom: 28px;
  position: relative;
}

.content-main:after {
  content: "";
  display: table;
  clear: both;
}

.content-main > h1:first-child {
  margin-top: -14px;
}

.site {
  -webkit-transition: -webkit-transform 324ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -moz-transition: -moz-transform 324ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -o-transition: -o-transform 324ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  transition: transform 324ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
}

.site_desktopnav-opened {
  -webkit-transition: -webkit-transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -moz-transition: -moz-transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -o-transition: -o-transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  transition: transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
}

.gap, .gap1, .gap2, .gap3 {
  margin-bottom: 28px;
}

.gap:after, .gap1:after, .gap2:after, .gap3:after {
  content: "";
  display: table;
  clear: both;
}

.gap1 {
  margin-bottom: 14px;
}

.gap2 {
  margin-bottom: 28px;
}

.gap3 {
  margin-bottom: 42px;
}

.link, .back-link, .linklist > li > a {
  display: inline-block;
  padding-left: 14px;
}

.back-link:before {
  content: "\f053";
  font-family: fontawesome;
  padding-right: 6px;
}

.news-tiles__more-link {
  color: #186E98 !important;
}

.news-tiles__more-link:hover{
  color: #10587B !important;
}

.more-link, .more-link-small, .toggle-more, .toggle-less {
  padding-right: 1em;
}

.more-link.btn, .btn.more-link-small, .btn.toggle-more, .btn.toggle-less {
  padding-right: 2em;
}

.more-link-small {
  font-weight: bold;
}

a.nav, a.nav:visited {
  color: #014A7B;
  font-weight: normal;
  text-decoration: none;
}

.pdfplan a.nav:hover {
  color: #7FB4D8;
  text-decoration: underline;
  background: none;
  border: 1px solid #014A7B;
}

.linklist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.linklist > li {
  margin: 0 0 0.2em 0;
  padding-left: 14px;
}

.linklist > li > a {
  margin-left: -14px;
}

.site-search {
  padding: 14px 0;
  background: #3670a1;
  margin-bottom: 28px;
}

.site-search:after {
  content: "";
  display: table;
  clear: both;
}

.data-table .table {
  background: #fff;
}

.data-table .table thead th {
  background: #ddd;
  border-color: #ccc;
}

.data-table .table tbody tr:hover {
  cursor: pointer;
}

.data-table .table tbody tr:hover td {
  background: #f2f5f8;
  color: #1f80d6;
}

.downloads {
  margin-bottom: 28px;
}

.video {
  margin-bottom: 28px;
}

.content-main {
  padding-bottom: 56px;
}

.nopadding {
  padding: 0;
}

/* rte style - start */

.bgHell,
.bgRot,
.bgDunkel,
.bgMittel {
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid;
}

a.add,
a.delete,
a.edit,
a.update,
a.copy,
a.paste,
a.cut,
a.activated,
a.deactivated {
  cursor: pointer;
  text-decoration: none;
}

a.add::before,
a.delete::before,
a.edit::before,
a.copy::before,
a.paste::before,
a.cut::before,
a.activated::before,
a.deactivated::before {
  font-family: 'FontAwesome';
  font-size: 0.9em;
  letter-spacing: 4px;
}

a.add::before {
  content: '\f055';
}

a.delete::before {
  content: '\f1f8';
}

a.edit::before {
  content: '\f040';
}

a.copy::before {
  content: '\f0c5';
}

a.paste::before {
  content: '\f0ea';
}

a.cut::before {
  content: '\f0c4';
}

a.activated::before {
  content: '\f0eb';
  color: #ff0;
}

a.deactivated::before {
  content: '\f0eb';
  color: #ccc;
}

a.arrow-right {
  float: right;

  &:after {
    font-family: 'FontAwesome';
    content: '\f18e';
    margin-left: 0.5em;
  }
}

a.arrow-up-right {
  float: right;

  &:after {
    font-family: 'FontAwesome';
    content: '\f01b';
    margin-left: 0.5em;
  }
}

.bgHell,
.bgDunkel,
.bgMittel {
  background-color: #D9EDF7 !important;
  border-color: #BCE8F1;
  color: #3A87AD;
}

.bgRot {
  background-color: #F2DEDE !important;
  border-color: #EBCCD1;
  color: #A9413F;
}

.bgSmall {
  font-size: 10px;
}

.indent {
  padding-left: 15px;
}

/* Lists -- @todo remove the most! */

/* Exclude navigation elements */
ul.nav li:before,
div.toggle-box ul li:before,
ul.teaser-group__wrapper li:before,
ul.tx-pagebrowse li:before,
ul.dropdown-menu li:before {
  content: "";
}

/* Teaser */

.extra-teaser .info-teaser__item-body {
  background: none repeat scroll 0 0 #d3e3f0;
}

/* rte style - end */

.tx-pagebrowse-pi1 td {
  padding-bottom: 10px !important;
}

/* --- Inhaltselemente -------------------- */

a[data-lightbox-type="image"] {
  position: relative;
  display: block;
}

a[data-lightbox-type="image"] .image__zoom {
  display: block;
  bottom: 5px;
  right: 5px;
}

.clearer {
  clear: both;
}

p.indent {
  margin: 0 17px;
}

a.external-link-new-window img {
  display: none;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.lastchanged,
.lastchanged a {
  color: #fff;
  font-size: 11px;
}

.divider.blue hr {
  background-color: #003F75;
  color: #003F75;
  height: 1px;
}

.divider.transparent hr {
  background-color: transparent;
  color: transparent;
  height: 0;
  visibility: hidden;
  margin: 10px 0;
}

.table-striped > tbody > tr:nth-child(2n) > th.bgHell {
  background-color: #ddd !important;
  color: #1d1d1b !important;
  border: 0 none !important;
}

/* images */
figcaption {
  margin-top: 2px;
  line-height: 1.2;
  color: #666;
}

/* fancy gimmicks */
.shadow {
  box-shadow: $shadow;
}

.lap {
  position: relative;
  margin-top: 12px;
  &:before {
    content: "";
    position: absolute;
    right: -$lap-height;
    top: -$lap-height;
    border: $lap-height solid transparent;
    border-left-color: $gray-dark;
    border-left-width: $lap-width;
    border-bottom-width: 0;
  }
}

// Shift local anchor targets due to wrong local targets in accordion elements
:target {
  scroll-margin-top: 60px;
}

// Skip Link only visible when focused
.skiplink {
  z-index: 1032;
  background: #003f75;
  color: #fff !important;
  padding: 10px 40px 10px 40px;
  left: 50%;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;

}

.skiplink:focus {
  transform: translateY(0%);
}

.no-pseudo {
  padding: 0;
}

.no-pseudo li::before {
  content: none !important;
}
