@import "../bootstrap/variables-custom";

.header-site {
  position: relative;
  background: #fff;
  z-index: $zindex-navbar-fixed + 1;

  .container {
    position: relative;
    height: 100px;
  }

  .header-site__corporate {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .header-site__corporate,
  .header-site__corporate img {
    height: 72px;
    width: auto;
  }

  .institution-title-line {
    border-top: 1px solid $gray-dark;

    .institution-title-line__container {
      min-height: 53px;
      height: auto;
    }

    .institution-title-line__image {
      padding-right: 10px;
      transform: translateY(-1px);
    }

    .institution-title-line__title {
      font-size: 1.2em;
      color: $brand-primary;
    }

    .institution-title_line_wrapper {
      display: flex;
      margin-bottom: 16px;
      margin-top: 17px;
    }
  }


  .container-own-logo {
    height: auto;
  }
}

/*
 * Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
 *
 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .header-site {
    .container {
      height: 70px;
    }

    .header-site__corporate,
    .header-site__corporate img {
      height: 48px;
    }

    .container-own-logo {
      height: auto;
    }
  }
}

/* Sticky mainnav header */
@media screen and (min-height: 640px) {
  .navigation-main {
    position: sticky;
    top: 0;
    z-index: $zindex-navbar-fixed;
  }
  // Adapts local anchors target to consider sticky navigation
  :target {
    scroll-margin-top: 110px;
  }
}
