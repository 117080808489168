/*! jQuery UI - v1.10.2 - 2013-04-17
* http://jqueryui.com
* Includes: jquery.ui.core.css, jquery.ui.resizable.css, jquery.ui.selectable.css, jquery.ui.accordion.css, jquery.ui.autocomplete.css, jquery.ui.button.css, jquery.ui.datepicker.css, jquery.ui.dialog.css, jquery.ui.menu.css, jquery.ui.progressbar.css, jquery.ui.slider.css, jquery.ui.spinner.css, jquery.ui.tabs.css, jquery.ui.tooltip.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Arial%2Csans-serif&fwDefault=normal&fsDefault=100%25&cornerRadius=0px&bgColorHeader=%23004477&bgTextureHeader=highlight_soft&bgImgOpacityHeader=75&borderColorHeader=%23004477&fcHeader=%23FFFFFF&iconColorHeader=%23222222&bgColorContent=%23ffffff&bgTextureContent=flat&bgImgOpacityContent=75&borderColorContent=%23e5eaee&fcContent=%23333333&iconColorContent=%23222222&bgColorDefault=%23e5eaee&bgTextureDefault=glass&bgImgOpacityDefault=75&borderColorDefault=%237f99b2&fcDefault=%23004477&iconColorDefault=%23888888&bgColorHover=%237f99b2&bgTextureHover=glass&bgImgOpacityHover=75&borderColorHover=%23004477&fcHover=%23ffffff&iconColorHover=%23454545&bgColorActive=%23ffffff&bgTextureActive=glass&bgImgOpacityActive=65&borderColorActive=%23e5eaee&fcActive=%23333333&iconColorActive=%23454545&bgColorHighlight=%23e5eaee&bgTextureHighlight=glass&bgImgOpacityHighlight=55&borderColorHighlight=%237f99b2&fcHighlight=%23004477&iconColorHighlight=%232e83ff&bgColorError=%23ffeeee&bgTextureError=glass&bgImgOpacityError=95&borderColorError=%23c9050f&fcError=%23c9050f&iconColorError=%23cd0a0a&bgColorOverlay=%23e5eaee&bgTextureOverlay=flat&bgImgOpacityOverlay=0&opacityOverlay=50&bgColorShadow=%237f99b2&bgTextureShadow=flat&bgImgOpacityShadow=0&opacityShadow=50&thicknessShadow=8px&offsetTopShadow=-8px&offsetLeftShadow=-8px&cornerRadiusShadow=8px
* Copyright 2013 jQuery Foundation and other contributors Licensed MIT */
.ui-helper-hidden {
  display: none
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none
}

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse
}

.ui-helper-clearfix:after {
  clear: both
}

.ui-helper-clearfix {
  min-height: 0
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0)
}

.ui-front {
  z-index: 100
}

.ui-state-disabled {
  cursor: default !important
}

.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat
}

.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.ui-resizable {
  position: relative
}

.ui-resizable-handle {
  position: absolute;
  font-size: .1px;
  display: block
}

.ui-resizable-disabled .ui-resizable-handle, .ui-resizable-autohide .ui-resizable-handle {
  display: none
}

.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0
}

.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0
}

.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%
}

.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%
}

.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px
}

.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px
}

.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px
}

.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px
}

.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted #000
}

.ui-accordion .ui-accordion-header {
  display: block;
  cursor: pointer;
  position: relative;
  margin-top: 2px;
  padding: .5em .5em .5em .7em;
  min-height: 0
}

.ui-accordion .ui-accordion-icons {
  padding-left: 2.2em
}

.ui-accordion .ui-accordion-noicons {
  padding-left: .7em
}

.ui-accordion .ui-accordion-icons .ui-accordion-icons {
  padding-left: 2.2em
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  left: .5em;
  top: 50%;
  margin-top: -8px
}

.ui-accordion .ui-accordion-content {
  padding: 1em 2.2em;
  border-top: 0;
  overflow: auto
}

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  z-index: 1000;
}

.ui-button {
  display: inline-block;
  position: relative;
  padding: 0;
  line-height: normal;
  margin-right: .1em;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  overflow: visible
}

.ui-button, .ui-button:link, .ui-button:visited, .ui-button:hover, .ui-button:active {
  text-decoration: none
}

.ui-button-icon-only {
  width: 2.2em
}

button.ui-button-icon-only {
  width: 2.4em
}

.ui-button-icons-only {
  width: 3.4em
}

button.ui-button-icons-only {
  width: 3.7em
}

.ui-button .ui-button-text {
  display: block;
  line-height: normal
}

.ui-button-text-only .ui-button-text {
  padding: .4em 1em
}

.ui-button-icon-only .ui-button-text, .ui-button-icons-only .ui-button-text {
  padding: .4em;
  text-indent: -9999999px
}

.ui-button-text-icon-primary .ui-button-text, .ui-button-text-icons .ui-button-text {
  padding: .4em 1em .4em 2.1em
}

.ui-button-text-icon-secondary .ui-button-text, .ui-button-text-icons .ui-button-text {
  padding: .4em 2.1em .4em 1em
}

.ui-button-text-icons .ui-button-text {
  padding-left: 2.1em;
  padding-right: 2.1em
}

input.ui-button {
  padding: .4em 1em
}

.ui-button-icon-only .ui-icon, .ui-button-text-icon-primary .ui-icon, .ui-button-text-icon-secondary .ui-icon, .ui-button-text-icons .ui-icon, .ui-button-icons-only .ui-icon {
  position: absolute;
  top: 50%;
  margin-top: -8px
}

.ui-button-icon-only .ui-icon {
  left: 50%;
  margin-left: -8px
}

.ui-button-text-icon-primary .ui-button-icon-primary, .ui-button-text-icons .ui-button-icon-primary, .ui-button-icons-only .ui-button-icon-primary {
  left: .5em
}

.ui-button-text-icon-secondary .ui-button-icon-secondary, .ui-button-text-icons .ui-button-icon-secondary, .ui-button-icons-only .ui-button-icon-secondary {
  right: .5em
}

.ui-buttonset {
  margin-right: 7px
}

.ui-buttonset .ui-button {
  margin-left: 0;
  margin-right: -.3em
}

input.ui-button::-moz-focus-inner, button.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0
}

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em
}

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
  top: 1px
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px
}

.ui-datepicker .ui-datepicker-next {
  right: 2px
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px
}

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px
}

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0
}

.ui-datepicker select.ui-datepicker-month-year {
  width: 100%
}

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 49%
}

.ui-datepicker table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  margin: 0 0 .4em
}

.ui-datepicker th {
  padding: .7em .3em;
  text-align: center;
  font-weight: 700;
  border: 0
}

.ui-datepicker td {
  border: 0;
  padding: 1px
}

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em;
  width: auto;
  overflow: visible
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left
}

.ui-datepicker.ui-datepicker-multi {
  width: auto
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0
}

.ui-datepicker-rtl {
  direction: rtl
}

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
  float: right
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px
}

.ui-dialog {
  position: absolute;
  top: 0;
  left: 0;
  padding: .2em;
  outline: 0
}

.ui-dialog .ui-dialog-titlebar {
  padding: .4em 1em;
  position: relative
}

.ui-dialog .ui-dialog-title {
  float: left;
  margin: .1em 0;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis
}

.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: .3em;
  top: 50%;
  width: 21px;
  margin: -10px 0 0 0;
  padding: 1px;
  height: 20px
}

.ui-dialog .ui-dialog-content {
  position: relative;
  border: 0;
  padding: .5em 1em;
  background: 0;
  overflow: auto
}

.ui-dialog .ui-dialog-buttonpane {
  text-align: left;
  border-width: 1px 0 0;
  background-image: none;
  margin-top: .5em;
  padding: .3em 1em .5em .4em
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
  float: right
}

.ui-dialog .ui-dialog-buttonpane button {
  margin: .5em .4em .5em 0;
  cursor: pointer
}

.ui-dialog .ui-resizable-se {
  width: 12px;
  height: 12px;
  right: -5px;
  bottom: -5px;
  background-position: 16px 16px
}

.ui-draggable .ui-dialog-titlebar {
  cursor: move
}

.ui-menu {
  list-style: none;
  padding: 2px;
  margin: 0;
  display: block;
  outline: 0
}

.ui-menu .ui-menu {
  margin-top: -3px;
  position: absolute
}

.ui-menu .ui-menu-item {
  margin: 0;
  padding: 0;
  width: 100%
}

.ui-menu .ui-menu-divider {
  margin: 5px -2px 5px -2px;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0
}

.ui-menu .ui-menu-item a {
  text-decoration: none;
  display: block;
  padding: 2px .4em;
  line-height: 1.5;
  min-height: 0;
  font-weight: 400
}

.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a.ui-state-active {
  font-weight: 400;
  margin: -1px
}

.ui-menu .ui-state-disabled {
  font-weight: 400;
  margin: .4em 0 .2em;
  line-height: 1.5
}

.ui-menu .ui-state-disabled a {
  cursor: default
}

.ui-menu-icons {
  position: relative
}

.ui-menu-icons .ui-menu-item a {
  position: relative;
  padding-left: 2em
}

.ui-menu .ui-icon {
  position: absolute;
  top: .2em;
  left: .2em
}

.ui-menu .ui-menu-icon {
  position: static;
  float: right
}

.ui-progressbar {
  height: 2em;
  text-align: left;
  overflow: hidden
}

.ui-progressbar .ui-progressbar-value {
  margin: -1px;
  height: 100%
}

.ui-progressbar .ui-progressbar-overlay {
  height: 100%;
  filter: alpha(opacity=25);
  opacity: .25
}

.ui-progressbar-indeterminate .ui-progressbar-value {
  background-image: none
}

.ui-slider {
  position: relative;
  text-align: left
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1.2em;
  height: 1.2em;
  cursor: default
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0
}

.ui-slider.ui-state-disabled .ui-slider-handle, .ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit
}

.ui-slider-horizontal {
  height: .8em
}

.ui-slider-horizontal .ui-slider-handle {
  top: -.3em;
  margin-left: -.6em
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0
}

.ui-slider-vertical {
  width: .8em;
  height: 100px
}

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0
}

.ui-spinner {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  vertical-align: middle
}

.ui-spinner-input {
  border: 0;
  background: 0;
  color: inherit;
  padding: 0;
  margin: .2em 0;
  vertical-align: middle;
  margin-left: .4em;
  margin-right: 22px
}

.ui-spinner-button {
  width: 16px;
  height: 50%;
  font-size: .5em;
  padding: 0;
  margin: 0;
  text-align: center;
  position: absolute;
  cursor: default;
  display: block;
  overflow: hidden;
  right: 0
}

.ui-spinner a.ui-spinner-button {
  border-top: 0;
  border-bottom: 0;
  border-right: 0
}

.ui-spinner .ui-icon {
  position: absolute;
  margin-top: -8px;
  top: 50%;
  left: 0
}

.ui-spinner-up {
  top: 0
}

.ui-spinner-down {
  bottom: 0
}

.ui-spinner .ui-icon-triangle-1-s {
  background-position: -65px -16px
}

.ui-tabs {
  position: relative;
  padding: .2em
}

.ui-tabs .ui-tabs-nav {
  margin: 0;
  padding: .2em .2em 0
}

.ui-tabs .ui-tabs-nav li {
  list-style: none;
  float: left;
  position: relative;
  top: 0;
  margin: 1px .2em 0 0;
  border-bottom-width: 0;
  padding: 0;
  white-space: nowrap
}

.ui-tabs .ui-tabs-nav li a {
  float: left;
  padding: .5em 1em;
  text-decoration: none
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
  margin-bottom: -1px;
  padding-bottom: 1px
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active a, .ui-tabs .ui-tabs-nav li.ui-state-disabled a, .ui-tabs .ui-tabs-nav li.ui-tabs-loading a {
  cursor: text
}

.ui-tabs .ui-tabs-nav li a, .ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active a {
  cursor: pointer
}

.ui-tabs .ui-tabs-panel {
  display: block;
  border-width: 0;
  padding: 1em 1.4em;
  background: 0
}

.ui-tooltip {
  padding: 8px;
  position: absolute;
  z-index: 9999;
  max-width: 300px;
  -webkit-box-shadow: 0 0 5px #aaa;
  box-shadow: 0 0 5px #aaa
}

body .ui-tooltip {
  border-width: 2px
}

.ui-widget {
  font-family: Arial, sans-serif;
  font-size: 100%
}

.ui-widget .ui-widget {
  font-size: 1em
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: Arial, sans-serif;
  font-size: 1em
}

.ui-widget-content {
  border: 1px solid #e5eaee;
  background: #fff 50% 50% repeat-x;
  color: #333
}

.ui-widget-content a {
  color: #333
}

.ui-widget-header {
  border: 1px solid #047;
  background: #047 50% 50% repeat-x;
  color: #FFF;
  font-weight: bold
}

.ui-widget-header a {
  color: #FFF
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: 1px solid #7f99b2;
  background: #e5eaee 50% 50% repeat-x;
  font-weight: normal;
  color: #047
}

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
  color: #047;
  text-decoration: none
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  border: 1px solid #047;
  background: #7f99b2 50% 50% repeat-x;
  font-weight: normal;
  color: #fff
}

.ui-state-hover a, .ui-state-hover a:hover, .ui-state-hover a:link, .ui-state-hover a:visited {
  color: #fff;
  text-decoration: none
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  font-weight: normal;
  color: #333
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
  color: #333;
  text-decoration: none
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid #7f99b2;
  background: #e5eaee 50% 50% repeat-x;
  color: #047
}

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
  color: #047
}

.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
  border: 1px solid #c9050f;
  background: #fee 50% 50% repeat-x;
  color: #c9050f
}

.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a {
  color: #c9050f
}

.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
  color: #c9050f
}

.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
  font-weight: bold
}

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter: Alpha(Opacity=70);
  font-weight: normal
}

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none
}

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35)
}

.ui-icon {
  width: 16px;
  height: 16px
}

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-top-left-radius: 0
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-top-right-radius: 0
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-left-radius: 0
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  border-bottom-right-radius: 0
}

.ui-widget-overlay {
  background: #e5eaee 50% 50% repeat-x;
  opacity: .5;
  filter: Alpha(Opacity=50)
}

.ui-widget-shadow {
  margin: -8px 0 0 -8px;
  padding: 8px;
  background: #7f99b2 50% 50% repeat-x;
  opacity: .5;
  filter: Alpha(Opacity=50);
  border-radius: 8px
}
