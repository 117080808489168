.footer-site {
  background: #003f75;
  padding-top: 28px;
}

@media (max-width: 767px) {
  .footer-site .site-search {
    margin: -28px -14px 28px -14px;
    padding: 20px 0;
    overflow: hidden;
  }
}

@media print {
  .footer-site {
    border-top: 1px solid #aaa;
    page-break-inside: avoid;
  }
}

@media print {
  .footer-site__row-1 {
    display: none;
  }
}

@media (max-width: 767px) {
  .footer-site__row-2 {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
}

@media print {
  .footer-site__row-2 {
    display: block;
    margin: 0;
  }

  .footer-site__row-2 .footer-site__col-1, .footer-site__row-2 .footer-site__col-2 {
    display: none;
  }
}

.footer-site__navigation {
  font-size: 0.875em;
  line-height: 1.2;
  margin-bottom: 28px;
  padding-bottom: 28px;
  border-bottom: 2px dotted #ccc;
  border-bottom: 2px dotted rgba(255, 255, 255, 0.4);
}

.footer-site__navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-site__navigation li {
  line-height: 1.3;
  margin-bottom: 0.4em;
}

.footer-site__navigation a {
  color: #fff !important;
}

@media screen and (min-width: 768px) {
  .footer-site__navigation {
    padding-top: 20px;
    border-bottom: 0;
  }
}

.footer-site__corporate {
  border-left: none;
  border-right: 2px dotted #ccc;
  border-right: 2px dotted rgba(255, 255, 255, 0.4);
  padding: 25px 0 35px 0;
  margin-bottom: 28px;
  display: none;
}

.footer-site__corporate .footer-site__corporate-content {
  visibility: hidden;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .footer-site__corporate {
    display: block;
  }
}

.footer-site__contact {
  margin-bottom: 28px;
  font-size: 0.875em;
  line-height: 1.2;
}

.footer-site__contact h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.4;
  color: #ddd;
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.3em;
}

.footer-site__contact p {
  color: #fff;
  line-height: 1.3;
  margin-bottom: 1em;
}

.footer-site__contact a {
  color: #fff !important;
}

@media print {
  .footer-site__contact a {
    color: #1d1d1b !important;
  }
}

.sharing {
  margin-bottom: 28px;
  overflow: hidden;
}

.sharing:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .sharing {
    display: table;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.sharing__wrp {
  margin-right: -3px;
}

.sharing__item {
  float: left;
  background: #3670a1;
  width: 76px;
  height: 62px;
  text-align: center;
  border-right: 2px solid #003f75;
  margin-left: -1px;
}

.sharing__item span {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.sharing__item i {
  margin-top: 19px;
}

.sharing__item:hover, .sharing__item:focus {
  background: #3fa4fa;
}

.sharing__item i {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  color: #fff;
  font-size: 26px;
}
