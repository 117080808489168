.swiper-container {
  width: 100%;
  height: 400px;

  .content {
    position: absolute;
    bottom: 0;
    background-color: rgba(204, 204, 204, 0.6);
    width: 100%;
    padding: 14px;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  top: 10px;
  height: 20px;
}

.swiper-wrapper-uhoh {
  margin-bottom: 20px;
}
