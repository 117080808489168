.gallery {
  margin: 0 -2% 14px -2%;
}

@media screen and (min-width: 768px) {
  .gallery {
    margin: 0 -4px 28px -4px;
  }
}

.gallery__wrp {
  list-style: none;
  margin: 0;
  padding: 0;
}

.gallery__item {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 2%;
}

.gallery-size-small .gallery__item {
  width: 48%;
}

@media screen and (min-width: 768px) {
  .gallery-size-small .gallery__item {
    width: 153px;
    margin: 4px;
  }
}

@media screen and (min-width: 1024px) {
  .gallery-size-small .gallery__item {
    width: 139px;
  }
}

.gallery-size-medium .gallery__item {
  width: 100%;
  margin: 4px 0;
}

@media screen and (min-width: 768px) {
  .gallery-size-medium .gallery__item {
    width: 153px;
    margin: 4px;
  }
}

@media screen and (min-width: 1024px) {
  .gallery-size-medium .gallery__item {
    width: 176px;
  }
}

.gallery-size-large .gallery__item {
  width: 100%;
  margin: 4px 0;
}

@media screen and (min-width: 768px) {
  .gallery-size-large .gallery__item {
    width: 234px;
    margin: 4px;
  }
}

@media screen and (min-width: 1024px) {
  .gallery-size-large .gallery__item {
    width: 237px;
  }
}

.gallery__item-src {
  width: 100%;
  height: auto;
}

.gallery__item-zoom {
  position: absolute;
  right: 10px;
  top: auto;
  bottom: 10px;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: -1px 0px 1px rgba(0, 0, 0, 0.8);
}
