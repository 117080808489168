.ui-autocomplete {
  margin-top: 14px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  padding: 0;
  z-index: 99999;
}

.ui-autocomplete li:hover {
  background-color: #f0f6fa;
}

.ui-autocomplete li:before {
  content: '';
}

.ui-menu .ui-menu-item a {
  padding: 2px 0.4em 0 !important;
}

/* Datepicker */
a.ui-datepicker-next,
a.ui-datepicker-prev {
  cursor: pointer;
  text-decoration: none;
}

a.ui-datepicker-next::before {
  content: '\f0da';
  color: #fff;
  font-family: 'FontAwesome';
  font-size: 1.4em;
  padding-left: 5px;
}

a.ui-datepicker-prev::before {
  content: '\f0d9';
  color: #fff;
  font-family: 'FontAwesome';
  font-size: 1.4em;
  padding-left: 5px;
}

.ui-dialog {
  z-index: 9999 !important;
}

@media (max-width: 767px) {
  #ui-id-1 {
    left: 0 !important;
    width: 100% !important;
  }
}
