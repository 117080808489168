.tradingbox__body {
  margin-top: 2px;
  background: #fff;
}

.tradingbox__items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tradingbox__item + .tradingbox__item {
  border-top: 2px solid #f3f3f3;
}

.tradingbox__item a {
  display: table;
  width: 100%;
  padding: 20px 14px;
  color: #1d1d1b;
  text-decoration: none !important;
}

.tradingbox__item a:hover, .tradingbox__item a:focus {
  color: #1f80d6;
}

.tradingbox__item-title {
  display: table-cell;
  font-weight: bold;
}

.tradingbox__item-meta {
  display: table-cell;
  text-align: right;
  white-space: nowrap;
}

.tradingbox__item-new {
  display: inline-block;
  min-width: 5em;
}

.tradingbox__item-new em {
  font-style: normal;
  display: inline-block;
  background: #003f75;
  color: #fff;
  padding: 2px 7px;
}
