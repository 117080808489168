.wohnungsboerse__item-overview {
  background: #d3e3f0;
  padding: 14px;
  margin-bottom: 28px;

  &:after {
    display: table;
    content: "";
    clear: both;
  }
}

@media screen and (min-width: 768px) {
  .wohnungsboerse__item-overview-image {
    float: left;
    width: 48%;
    margin-right: 4%;

    .image {
      margin-bottom: 0;
    }
  }

  .wohnungsboerse__item-overview-text {
    overflow: hidden;
  }
}
