.tx-jfmulticontent-pi1 .subcolumns .c50l {
  width: 48%;
  float: left;
}

.tx-jfmulticontent-pi1 .subcolumns .c50r {
  width: 48%;
  float: left;
}

.tx-jfmulticontent-pi1 .subcolumns:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

.tx-jfmulticontent-pi1 .tab-content {
  padding: 17px 0;
}

/* jfmulticontent accordions which lay inner irfaq - Remove wrong padding left and right */
div.tx-jfmulticontent-pi1 div.tab-content div.tab-pane div.col-sm-12 {
  padding-left: 0;
  padding-right: 0;
}
