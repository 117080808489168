.sub1col.nzg_relatedtags .csc-textpic {
  overflow: hidden;
}

/* @todo really needed? */
.csc-text ul li ul li:before,
.csc-textpic ul li ul li:before,
.csc-default > ul ul > li:before,
ul.csc-bulletlist ul > li:before,
ul.csc-bulletlist-0 ul > li:before,
ul.csc-bulletlist-1 ul > li:before {
  content: "-";
}

/* @todo really needed? */
ul.csc-bulletlist-0 h1,
ul.csc-bulletlist-0 h2,
ul.csc-bulletlist-0 h3,
ul.csc-bulletlist-0 h4 {
  margin-left: -19px;
}

.csc-textpic-above img {
  margin-bottom: 14px;
}

.csc-textpic-left img {
  margin-right: 14px;
}

div.csc-textpic .csc-textpic-imagewrap img {
  display: inline;
  vertical-align: text-top;
}

.csc-textpic-intext-left ol,
.csc-textpic-intext-left ul {
  padding-left: 17px;
}

div.csc-textpic,
div.csc-textpic div.csc-textpic-imagerow,
ul.csc-uploads li {
  overflow: visible;
}


.csc-textpic-caption {
  font-size: 10px;
}


.csc-default .divider {
  clear: both;
}

.col-sm-3 .csc-textpic-image img {
  width: 100% !important;
}

div.csc-textpic .csc-textpic-imagewrap figure.csc-textpic-image {
  line-height: normal;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) and (max-width: 1023px) and (min-width: 768px) {
  div.csc-textpic .csc-textpic-imagewrap img {
    width: 100% !important;
    height: auto !important;
  }

  .col-sm-3 .csc-textpic-image img {
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .col-sm-3 .csc-textpic-image img {
    width: 100% !important;
  }

  div.csc-textpic .csc-textpic-imagewrap img {
    width: 100% !important;
    height: auto !important;
  }
}

/* Headers */
.csc-header-alignment-center {
  text-align: center;
}

.csc-header-alignment-right {
  text-align: right;
}

.csc-header-alignment-left {
  text-align: left;
}

div.csc-textpic-responsive, div.csc-textpic-responsive * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Set padding for tables */
div.csc-textpic .csc-textpic-imagewrap table {
  border-collapse: collapse;
  border-spacing: 0;
}

div.csc-textpic .csc-textpic-imagewrap table tr td {
  padding: 0;
  vertical-align: top;
}

/* Settings for figure and figcaption (HTML5) */
div.csc-textpic .csc-textpic-imagewrap figure, div.csc-textpic figure.csc-textpic-imagewrap {
  margin: 0;
  display: table;
}

/* Captions */
figcaption.csc-textpic-caption {
  display: table-caption;
}

.csc-textpic-caption {
  text-align: left;
  caption-side: bottom;
}

div.csc-textpic-caption-c .csc-textpic-caption, .csc-textpic-imagewrap .csc-textpic-caption-c {
  text-align: center;
}

div.csc-textpic-caption-r .csc-textpic-caption, .csc-textpic-imagewrap .csc-textpic-caption-r {
  text-align: right;
}

div.csc-textpic-caption-l .csc-textpic-caption, .csc-textpic-imagewrap .csc-textpic-caption-l {
  text-align: left;
}

/* Float the columns */
div.csc-textpic div.csc-textpic-imagecolumn {
  float: left;
}

/* Border just around the image */
div.csc-textpic-border div.csc-textpic-imagewrap img {
  border: 2px solid black;
  padding: 0px 0px;
}

div.csc-textpic .csc-textpic-imagewrap img {
  border: none;
  display: block;
}

/* Space below each image (also in-between rows) */
div.csc-textpic .csc-textpic-imagewrap .csc-textpic-image {
  margin-bottom: 10px;
}

div.csc-textpic .csc-textpic-imagewrap .csc-textpic-imagerow-last .csc-textpic-image {
  margin-bottom: 0;
}

/* colSpace around image columns, except for last column */
div.csc-textpic-imagecolumn, td.csc-textpic-imagecolumn .csc-textpic-image {
  margin-right: 10px;
}

div.csc-textpic-imagecolumn.csc-textpic-lastcol, td.csc-textpic-imagecolumn.csc-textpic-lastcol .csc-textpic-image {
  margin-right: 0;
}

/* Add margin from image-block to text (in case of "Text & Images") */
div.csc-textpic-intext-left .csc-textpic-imagewrap,
div.csc-textpic-intext-left-nowrap .csc-textpic-imagewrap {
  margin-right: 10px;
  margin-bottom: 10px;
}

div.csc-textpic-intext-right .csc-textpic-imagewrap,
div.csc-textpic-intext-right-nowrap .csc-textpic-imagewrap {
  margin-left: 10px;
}

/* Positioning of images: */

/* Center (above or below) */
div.csc-textpic-center .csc-textpic-imagewrap, div.csc-textpic-center figure.csc-textpic-imagewrap {
  overflow: hidden;
}

div.csc-textpic-center .csc-textpic-center-outer {
  position: relative;
  float: right;
  right: 50%;
}

div.csc-textpic-center .csc-textpic-center-inner {
  position: relative;
  float: right;
  right: -50%;
}

/* Right (above or below) */
div.csc-textpic-right .csc-textpic-imagewrap {
  float: right;
}

div.csc-textpic-right div.csc-textpic-text {
  clear: right;
}

/* Left (above or below) */
div.csc-textpic-left .csc-textpic-imagewrap {
  float: left;
}

div.csc-textpic-left div.csc-textpic-text {
  clear: left;
}

/* Left (in text) */
div.csc-textpic-intext-left .csc-textpic-imagewrap {
  float: left;
}

/* Right (in text) */
div.csc-textpic-intext-right .csc-textpic-imagewrap {
  float: right;
}

/* Right (in text, no wrap around) */
div.csc-textpic-intext-right-nowrap .csc-textpic-imagewrap {
  float: right;
}

/* Left (in text, no wrap around) */
div.csc-textpic-intext-left-nowrap {
  .csc-textpic-imagewrap {
    float: left;
  }

  div.csc-textpic-text {
    overflow: hidden;

    div.csc-textpicHeader {
      margin-top: -22px;
    }
  }
}

div.csc-textpic div.csc-textpic-imagerow-last, div.csc-textpic div.csc-textpic-imagerow-none div.csc-textpic-last {
  margin-bottom: 0;
}

/* Browser fixes: */

/* Fix for unordered and ordered list with image */
.csc-textpic-text ol, .csc-textpic-text ul {
  overflow: hidden;
}

/* File Links */
ul.csc-uploads {
  padding: 0;
}

ul.csc-uploads li {
  list-style: none outside none;
  margin: 1em 0;
}

ul.csc-uploads img {
  float: left;
  margin-right: 1em;
  vertical-align: top;
}

ul.csc-uploads span {
  display: block;
}

ul.csc-uploads span.csc-uploads-fileName {
  text-decoration: underline;
}
