ul.tx-pagebrowse li.tx-pagebrowse-first,
ul.tx-pagebrowse li.tx-pagebrowse-prev,
ul.tx-pagebrowse li.tx-pagebrowse-next,
ul.tx-pagebrowse li.tx-pagebrowse-last,
ul.tx-pagebrowse li.tx-pagebrowse-current,
ul.tx-pagebrowse li.tx-pagebrowse-page,
ul.tx-pagebrowse li.tx-pagebrowse-pages ol li {
  background-color: #fff !important;
  border: 1px solid #ddd !important;
  float: left;
  margin-left: -1px;
  padding: 10px 12px 10px;
  position: relative;
  text-decoration: none;
}

/* This is needed because since 6.2 the CSS-File is not loaded automatically any
more although it is referenced in EXT:pagebrowse template file */
.tx-pagebrowse {
  list-style: none inside none;
  padding: 0 0;
  margin: 0 0;
  text-align: center;
  width: 100%;
}

.tx-pagebrowse li {
  display: inline;
  list-style: none;
  margin: 0 0;
  padding: 0 5px 0 0;
}

li.tx-pagebrowse-pages {
  width: 80%;
}

li.tx-pagebrowse-pages ol {
  display: inline;
  margin: 0 0;
  padding: 0 10px;
}
