//
// Panels
// --------------------------------------------------


// Base class
.panel {
  margin-bottom: $line-height-computed;
  background-color: $panel-bg;
  border: 1px solid transparent;
  border-radius: $panel-border-radius;
  @include box-shadow(0 1px 1px rgba(0,0,0,.05));
}

// Panel contents
.panel-body {
  padding: $panel-body-padding;
  @include clearfix;
}

// Optional heading
.panel-heading {
  padding: $panel-heading-padding;
  border-bottom: 1px solid transparent;
  @include border-top-radius(($panel-border-radius - 1));

  > .dropdown .dropdown-toggle {
    color: inherit;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

// Within heading, strip any `h*` tag of its default margins for spacing.
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: ceil(($font-size-base * 1.125));
  color: inherit;

  > a,
  > small,
  > .small,
  > small > a,
  > .small > a {
    color: inherit;
  }
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
  padding: $panel-footer-padding;
  background-color: $panel-footer-bg;
  border-top: 1px solid $panel-inner-border;
  @include border-bottom-radius(($panel-border-radius - 1));
}

.panel {
  &.panel-teaser {
    border: none;
    box-shadow: $shadow;
    margin-top: $lap-height;
    position: relative;

    >.panel-heading {
      padding: 0;
      border: 0;
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
    >.panel-body {
      > h1, h2, h3, h4 {
        margin-top: 0;
      }
      > .teaser-image-background ~ .csc-textpic-text {
        position: relative;
      }
      > .teaser-image-background {
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        max-width: 100%;
      }
      &.csc-header {
        padding: 14px 14px 0 14px;
        margin-bottom: -14px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      right: -$lap-height;
      top: -$lap-height;
      border: $lap-height solid transparent;
      border-left-color: $gray-dark;
      border-left-width: $lap-width;
      border-bottom-width: 0;
    }
    &.panel-teaser-image-left {
      > .panel-body {
        overflow: hidden;
        > .csc-textpic-imagewrap {
          float: left;
          margin: -14px 14px -14px -14px;
        }
        > .csc-textpic-text {
          overflow: auto;
        }
      }
    }
    &.panel-teaser-image-right {
      > .panel-body {
        overflow: hidden;
        > .csc-textpic-imagewrap {
          float: right;
          margin: -14px -14px -14px 14px;
        }
        > .csc-textpic-text {
          overflow: auto;
        }
      }
    }
  }

  &.blue-bright {
    background-color: $blue-bright;
    a {
      color: $brand-primary;
    }
    &.panel-teaser::before {
      border-left-color: darken($blue-bright, $lap-darkening);
    }
  }
  &.yellow-muted {
    background-color: $yellow-muted;
    &.panel-teaser::before {
      border-left-color: darken($yellow-muted, $lap-darkening);
    }
  }
  &.lightgreen-muted {
    background-color: $lightgreen-muted;
    &.panel-teaser::before {
      border-left-color: darken($lightgreen-muted, $lap-darkening);
    }
  }
  &.orange-shiny {
    background-color: $orange-shiny;
    &.panel-teaser::before {
      border-left-color: darken($orange-shiny, $lap-darkening);
    }
  }
  &.gray-muted {
    background-color: $gray-muted;
    &.panel-teaser::before {
      border-left-color: darken($gray-muted, $lap-darkening);
    }
  }
  &.green-shiny {
    background-color: $green-shiny;
    &.panel-teaser::before {
      border-left-color: darken($green-shiny, $lap-darkening);
    }
  }
  &.blue-shiny {
    background-color: $blue-shiny;
    &.panel-teaser::before {
      border-left-color: darken($blue-shiny, $lap-darkening);
    }
  }
  &.blue-muted {
    background-color: $blue-muted;
    &.panel-teaser::before {
      border-left-color: darken($blue-muted, $lap-darkening);
    }
  }
  &.blue-bright-muted {
    background-color: $blue-bright-muted;
    &.panel-teaser::before {
      border-left-color: darken($blue-bright-muted, $lap-darkening);
    }
  }
  &.red-shiny {
    background-color: $red-shiny;
    &.panel-teaser::before {
      border-left-color: darken($red-shiny, $lap-darkening);
    }
  }
  &.red-muted {
    background-color: $red-muted;
    &.panel-teaser::before {
      border-left-color: darken($red-muted, $lap-darkening);
    }
  }
  &.blue-dark {
    background-color: $brand-primary;
    &.panel-teaser::before {
      border-left-color: darken($brand-primary, $lap-darkening);
    }
  }
  &.green-dark-muted {
    background-color: $green-dark-muted;
    &.panel-teaser::before {
      border-left-color: darken($green-dark-muted, $lap-darkening);
    }
  }
  &.purple-muted {
    background-color: $purple-muted;
    &.panel-teaser::before {
      border-left-color: darken($purple-muted, $lap-darkening);
    }
  }

  &.text-black-contrast-high {
    color: #1d1d1b;
    h1, h2, h3, h4,
    ul.checked li:before {
      color: #1d1d1b;
    }
    a {
      color: #1d1d1b;
      text-decoration: underline;
    }
  }

  &.inverse-contrast {
    color: #fff;
    h1, h2, h3, h4,
    ul.checked li:before {
      color: #fff;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  &.panel-text {
    > .panel-heading {
      padding: 14px;
      > h1, h2, h3, h4 {
        margin-top: 0;
      }
    }
    > .panel-body {
      padding: 14px;
    }
    > .panel-heading + .panel-body {
      margin-top: -28px;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .panel.panel-teaser {
    &.panel-teaser-image-left, &.panel-teaser-image-right {
      > .panel-body > .csc-textpic-imagewrap {
        float: none;
        width: 100%;
        height: auto;
        margin: -14px -14px 14px -14px;
        img {
          width: 110%;
        }
      }
    }
  }
}



// List groups in panels
//
// By default, space out list group content from panel headings to account for
// any kind of custom content between the two.

.panel {
  > .list-group,
  > .panel-collapse > .list-group {
    margin-bottom: 0;

    .list-group-item {
      border-width: 1px 0;
      border-radius: 0;
    }

    // Add border top radius for first one
    &:first-child {
      .list-group-item:first-child {
        border-top: 0;
        @include border-top-radius(($panel-border-radius - 1));
      }
    }

    // Add border bottom radius for last one
    &:last-child {
      .list-group-item:last-child {
        border-bottom: 0;
        @include border-bottom-radius(($panel-border-radius - 1));
      }
    }
  }
  > .panel-heading + .panel-collapse > .list-group {
    .list-group-item:first-child {
      @include border-top-radius(0);
    }
  }
}
// Collapse space between when there's no additional content.
.panel-heading + .list-group {
  .list-group-item:first-child {
    border-top-width: 0;
  }
}
.list-group + .panel-footer {
  border-top-width: 0;
}

// Tables in panels
//
// Place a non-bordered `.table` within a panel (not within a `.panel-body`) and
// watch it go full width.

.panel {
  > .table,
  > .table-responsive > .table,
  > .panel-collapse > .table {
    margin-bottom: 0;

    caption {
      padding-left: $panel-body-padding;
      padding-right: $panel-body-padding;
    }
  }
  // Add border top radius for first one
  > .table:first-child,
  > .table-responsive:first-child > .table:first-child {
    @include border-top-radius(($panel-border-radius - 1));

    > thead:first-child,
    > tbody:first-child {
      > tr:first-child {
        border-top-left-radius: ($panel-border-radius - 1);
        border-top-right-radius: ($panel-border-radius - 1);

        td:first-child,
        th:first-child {
          border-top-left-radius: ($panel-border-radius - 1);
        }
        td:last-child,
        th:last-child {
          border-top-right-radius: ($panel-border-radius - 1);
        }
      }
    }
  }
  // Add border bottom radius for last one
  > .table:last-child,
  > .table-responsive:last-child > .table:last-child {
    @include border-bottom-radius(($panel-border-radius - 1));

    > tbody:last-child,
    > tfoot:last-child {
      > tr:last-child {
        border-bottom-left-radius: ($panel-border-radius - 1);
        border-bottom-right-radius: ($panel-border-radius - 1);

        td:first-child,
        th:first-child {
          border-bottom-left-radius: ($panel-border-radius - 1);
        }
        td:last-child,
        th:last-child {
          border-bottom-right-radius: ($panel-border-radius - 1);
        }
      }
    }
  }
  > .panel-body + .table,
  > .panel-body + .table-responsive,
  > .table + .panel-body,
  > .table-responsive + .panel-body {
    border-top: 1px solid $table-border-color;
  }
  > .table > tbody:first-child > tr:first-child th,
  > .table > tbody:first-child > tr:first-child td {
    border-top: 0;
  }
  > .table-bordered,
  > .table-responsive > .table-bordered {
    border: 0;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th:first-child,
        > td:first-child {
          border-left: 0;
        }
        > th:last-child,
        > td:last-child {
          border-right: 0;
        }
      }
    }
    > thead,
    > tbody {
      > tr:first-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
    > tbody,
    > tfoot {
      > tr:last-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
  }
  > .table-responsive {
    border: 0;
    margin-bottom: 0;
  }
}


// Collapsible panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
  &.accordion {
    background-color: white;
    padding: 20px 20px 5px 0;
    box-shadow: $shadow;
    >.panel-default {
      box-shadow: none;
      border: none;
      margin-bottom: 25px;
      >.panel-heading {
        padding: 0;
        margin-left: -10px;
        position: relative;
        background-color: $blue-muted;
        &::before {
          content: "";
          position: absolute;
          left: -6px;
          top: -6px;
          right: initial;
          border: 6px solid transparent;
          border-right-color: $gray-darker;
          border-right-width: 10px;
          border-bottom-width: 0;
        }
        >.panel-title {
          a {
            color: white;
            padding: 15px 40px 15px 40px;
            display: block;
            &::before {
              content: "\f106";
              right: 15px;
              font-size: 30px;
              line-height: 19px;
              font-family: 'FontAwesome';
              font-style: normal;
              position: absolute;
            }
            &.collapsed::before {
              content: "\f107";
            }
          }
        }
      }
    }
  }

  margin-bottom: $line-height-computed;

  // Tighten up margin so it's only between panels
  .panel {
    margin-bottom: 0;
    border-radius: $panel-border-radius;

    + .panel {
      margin-top: 5px;
    }
  }

  .panel-heading {
    border-bottom: 0;

    + .panel-collapse > .panel-body,
    + .panel-collapse > .list-group {
      border-top: 1px solid $panel-inner-border;
    }
  }

  .panel-footer {
    border-top: 0;
    + .panel-collapse .panel-body {
      border-bottom: 1px solid $panel-inner-border;
    }
  }
}


// Contextual variations
.panel-default {
  @include panel-variant($panel-default-border, $panel-default-text, $panel-default-heading-bg, $panel-default-border);
}
.panel-primary {
  @include panel-variant($panel-primary-border, $panel-primary-text, $panel-primary-heading-bg, $panel-primary-border);
}
.panel-success {
  @include panel-variant($panel-success-border, $panel-success-text, $panel-success-heading-bg, $panel-success-border);
}
.panel-info {
  @include panel-variant($panel-info-border, $panel-info-text, $panel-info-heading-bg, $panel-info-border);
}
.panel-warning {
  @include panel-variant($panel-warning-border, $panel-warning-text, $panel-warning-heading-bg, $panel-warning-border);
}
.panel-danger {
  @include panel-variant($panel-danger-border, $panel-danger-text, $panel-danger-heading-bg, $panel-danger-border);
}
