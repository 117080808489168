#tx-solr-search .result-content:after {
  content: " [...]";
}

li.tx_solr_new_autocomplete {
  min-width: 260px;
  margin: 0;
  padding: 5px 15px !important;
  cursor: pointer;
  -webkit-transition: background-color 200ms;
  -moz-transition: background-color 200ms;
  -o-transition: background-color 200ms;
  transition: background-color 200ms;
}

li.tx_solr_new_autocomplete + li.tx_solr_new_autocomplete {
  border-top: 1px solid #E5EAEE;
}

li.tx_solr_new_autocomplete a, li.tx_solr_new_further a {
  color: 0079AD !important;
}

li.tx_solr_new_autocomplete a:hover, li.tx_solr_new_further a:hover {
  text-decoration: underline !important;
}

li.tx_solr_new_autocomplete div.desc {
  font-size: 0.8em;
  display: block;
  padding: 0 0.5em;
}

li.tx_solr_new_autocomplete:hover {
  background: #f0f6fa;
  color: #003f75;
  border: none;
}

li.tx_solr_new_autocomplete div.desc a {
  display: inline !important;
  padding: 0 !important;
}

li.tx_solr_new_further, li.tx_solr_new_further a {
  display: block;
  width: 100%;
  font-size: 0.9em;
  padding: 0 0.5em;
}

ul.facets {
  padding-left: 0;
}

i.search-icon {
  color: #ccc;
  display: block;
  text-align: center;
}

div.search-autocomplete-border {
  border: 1px solid #ccc;
  height: auto;
  width: 32px;
}

li.facet-option a.active {
  font-weight: bold;
}

@media (max-width: 767px) {
  .tx-solr > div#tx-solr-search {
    width: auto;
    float: none;
  }

  .tx-solr > div#tx-solr-search-functions {
    display: none;
    width: 0;
    float: none;
  }
}
