
.info-tiles {
  margin-bottom: 28px;
}

.info-tiles__item-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.info-tiles__item {
  margin-bottom: 7px;
  background: #fff;
  position: relative;
  min-height: 90px;
}

.info-tiles__item:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 768px) {
  .info-tiles_type-standalone .info-tiles__item {
    height: 120px;
  }
}

@media screen and (min-width: 1024px) {
  .info-tiles_type-standalone .info-tiles__item {
    height: 140px;
  }
}

@media print {
  .info-tiles__item {
    min-height: 0;
    height: auto;
  }
}

.info-tiles__item-body {
  display: block;
  position: static;
  height: 100%;
  text-decoration: none !important;
}

@media screen and (min-width: 768px) {
  .info-tiles_type-standalone .info-tiles__item-body {
    position: relative;
  }
}

@media print {
  .info-tiles__item-body {
    height: auto;
  }
}

.info-tiles__item-content {
  position: static;
  padding: 14px;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .info-tiles_type-standalone .info-tiles__item-content {
    position: relative;
  }
}

@media print {
  .info-tiles__item-content {
    padding: 0;
    height: auto;
  }
}

.info-tiles__item-title {
  font-weight: normal;
  font-size: 1em;
  line-height: 1.1 !important;
  color: #0079AD;
  margin-bottom: 0.4em;
  max-height: 3.3em;
  overflow: hidden;
  display: block;
}

.info-tiles__item-body:hover .info-tiles__item-title {
  color: #1f80d6;
}

@media screen and (min-width: 768px) {
  .info-tiles_type-standalone .info-tiles__item-title {
    font-size: 1.2em;
    line-height: 1.2;
  }
}

@media screen and (min-width: 1024px) {
  .info-tiles_type-standalone .info-tiles__item-title {
    font-size: 1.4em;
    line-height: 1.2;
  }
}

.info-tiles__item-footer {
  position: static;
  left: 14px;
  right: 14px;
  bottom: 14px;
  font-size: 13px;
  line-height: 1.2;
  color: #878e8a;
  max-height: 2.5em;
  overflow: hidden;
  display: block;
}

@media screen and (min-width: 768px) {
  .info-tiles_type-standalone .info-tiles__item-footer {
    position: absolute;
  }
}

@media print {
  .info-tiles__item-footer {
    position: static;
  }
}

.info-tiles__more {
  display: block;
  font-size: 1.2em;
  line-height: 2;
}

.info-tiles__calendar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80px;
  padding: 18px 4px 0 7px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: normal;
  line-height: 1;
}

.info-tiles__calendar > div {
  display: table;
  margin: 0 auto;
}

.info-tiles__calendar + .info-tiles__item-content {
  margin-left: 80px;
}

@media print {
  .info-tiles__calendar + .info-tiles__item-content {
    min-height: 80px;
  }
}

@media screen and (min-width: 768px) {
  .info-tiles_type-standalone .info-tiles__calendar {
    width: 100px;
  }

  .info-tiles_type-standalone .info-tiles__calendar + .info-tiles__item-content {
    margin-left: 100px;
  }
}

@media screen and (min-width: 1024px) {
  .info-tiles_type-standalone .info-tiles__calendar {
    width: 140px;
  }

  .info-tiles_type-standalone .info-tiles__calendar + .info-tiles__item-content {
    margin-left: 140px;
  }
}

@media print {
  .info-tiles__calendar {
    width: 80px;
    border: 1px solid #aaa;
  }

  .info-tiles__calendar + .info-tiles__item-content {
    margin-left: 100px;
  }
}

.info-tiles__calendar-dayofweek {
  display: block;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .info-tiles_type-standalone .info-tiles__calendar-dayofweek {
    font-size: 13px;
  }
}

@media screen and (min-width: 1024px) {
  .info-tiles_type-standalone .info-tiles__calendar-dayofweek {
    font-size: 14px;
  }
}

.info-tiles__calendar-day {
  font-size: 36px;
  font-weight: 100;
}

@media screen and (min-width: 768px) {
  .info-tiles_type-standalone .info-tiles__calendar-day {
    font-size: 52px;
  }
}

@media screen and (min-width: 1024px) {
  .info-tiles_type-standalone .info-tiles__calendar-day {
    font-size: 78px;
  }
}

@media print {
  .info-tiles__calendar-day {
    font-size: 36px;
  }
}

.info-tiles__calendar-month {
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .info-tiles_type-standalone .info-tiles__calendar-month {
    font-size: 13px;
  }
}

@media screen and (min-width: 1024px) {
  .info-tiles_type-standalone .info-tiles__calendar-month {
    font-size: 14px;
  }
}

.info-tiles__item .info-tiles__calendar {
  background: #0079AD;
}

.info-tiles__item + .info-tiles__item .info-tiles__calendar {
  background: #E47800;
}

.info-tiles__item + .info-tiles__item + .info-tiles__item .info-tiles__calendar {
  background: #005826;
}

.info-tiles__item + .info-tiles__item + .info-tiles__item + .info-tiles__item .info-tiles__calendar {
  background: #bc2323;
}

.newsbox .info-tiles {
  margin-bottom: 0;
}

.newsbox .info-tiles__item {
  margin-bottom: 2px;
}

.newsbox .info-tiles__item-title {
  font-weight: bold;
  color: #1d1d1b;
}

div.info-tiles ul li:before {
  content: "";
}
