.container,
.navigation-main__wrp-2,
.navigation-main__wrp-3 {
  margin-right: auto;
  margin-left: auto;
  padding-left: 14px;
  padding-right: 14px;
}

.container:before,
.navigation-main__wrp-2:before,
.navigation-main__wrp-3:before,
.container:after,
.navigation-main__wrp-2:after,
.navigation-main__wrp-3:after {
  content: " ";
  display: table;
}

.container:after,
.navigation-main__wrp-2:after,
.navigation-main__wrp-3:after {
  clear: both;
}

.container > .navbar-header,
.navigation-main__wrp-2 > .navbar-header,
.navigation-main__wrp-3 > .navbar-header,
.container > .navbar-collapse,
.navigation-main__wrp-2 > .navbar-collapse,
.navigation-main__wrp-3 > .navbar-collapse {
  margin-right: -14px;
  margin-left: -14px;
}

.container .jumbotron,
.navigation-main__wrp-2 .jumbotron,
.navigation-main__wrp-3 .jumbotron {
  border-radius: 6px;
}

@media (min-width: 768px) {
  .container,
  .navigation-main__wrp-2,
  .navigation-main__wrp-3 {
    width: 756px;
  }

  .container > .navbar-header,
  .navigation-main__wrp-2 > .navbar-header,
  .navigation-main__wrp-3 > .navbar-header,
  .container > .navbar-collapse,
  .navigation-main__wrp-2 > .navbar-collapse,
  .navigation-main__wrp-3 > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }

  .navbar > .container .navbar-brand,
  .navbar > .navigation-main__wrp-2 .navbar-brand,
  .navbar > .navigation-main__wrp-3 .navbar-brand {
    margin-left: -14px;
  }
}

@media screen and (min-width: 768px) {
  .container .jumbotron,
  .navigation-main__wrp-2 .jumbotron,
  .navigation-main__wrp-3 .jumbotron {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1024px) {
  .container,
  .navigation-main__wrp-2,
  .navigation-main__wrp-3 {
    width: 1008px;
  }
}

@media (min-width: 1050px) {
  .container,
  .navigation-main__wrp-2,
  .navigation-main__wrp-3 {
    width: 1008px;
  }
}

@media print {
  .navigation-main {
    display: none;
  }
}

.navigation-main__wrp-1 {
  background: #003f75;
}

.navigation-main__wrp-2, .navigation-main__wrp-3 {
  padding: 0;
  position: relative;
}

.navigation-main__wrp-2:after, .navigation-main__wrp-3:after {
  content: "";
  display: table;
  clear: both;
}

.navigation-main__wrp-3 .site-search {
  margin-bottom: 0;
}

.navigation-main__section > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation-main__section > ul > li > a {
  color: #fff;
}

.navigation-main__section-title {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.navigation-main__button-group:after {
  content: "";
  display: table;
  clear: both;
}

.navigation-main__button {
  float: left;
  width: 60px;
  height: 54px;
  cursor: pointer;
  border-right: 1px solid #3670a1;
}

.navigation-main__button i {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

.navigation-main__button span {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.navigation-main__button:hover, .navigation-main__button:focus {
  background: #145389;
  text-decoration: none;
}

.navigation-main__button-navigation i {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *display: inline;
  height: 19px;
  width: 19px;
  margin-top: 17px;
  color: #fff;
  font-size: 22px;
}

.navigation-main__button-search i {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *display: inline;
  height: 15px;
  width: 15px;
  margin-top: 19px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

.navigation-main__button-login i {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *display: inline;
  height: 17px;
  width: 16px;
  color: #fff;
  font-size: 19px;
  margin: 19px 25px;
}

.navigation-main_logged-in .navigation-main__button-login i {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *display: inline;
  height: 17px;
  width: 16px;
}

@media screen and (min-width: 1024px) {
  .navigation-main__button-group {
    float: right;
  }

  .navigation-main__button-navigation {
    display: none;
  }
}

.navigation-main__language-select {
  float: right;
  text-align: right;
  padding: 0 14px 0 5px;
  line-height: 54px;
  color: #fff !important;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none !important;
}

.navigation-main__language-select:hover, .navigation-main__language-select:focus {
  background: #145389;
}

.navigation-main__button_active, .navigation-main__button_active:hover, .navigation-main__button_active:focus {
  background: #3670a1;
  text-decoration: none;
}

.navigation-main__toggle-content {
  display: none;
}

.navigation-main__panel {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0 linear, width 0 linear;
  -webkit-transition-delay: 900ms, 900ms;
  -moz-transition: height 0 linear 900ms, width 0 linear 900ms;
  -o-transition: height 0 linear 900ms, width 0 linear 900ms;
  transition: height 0 linear 900ms, width 0 linear 900ms;
  width: 0;
}

.navigation-main__panel > * > * {
  opacity: 0;
}

.csstransforms3d .navigation-main__panel > * > * {
  -webkit-transform: translate3d(14px, 0, 0);
  -moz-transform: translate3d(14px, 0, 0);
  -ms-transform: translate3d(14px, 0, 0);
  -o-transform: translate3d(14px, 0, 0);
  transform: translate3d(14px, 0, 0);
  -webkit-transition: all 900ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -moz-transition: all 900ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  -o-transition: all 900ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  transition: all 900ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
}

.navigation-main__panel_state-active {
  height: auto;
  width: auto;
}

.navigation-main__panel_state-active > * > * {
  opacity: 1;
}

.csstransforms3d .navigation-main__panel_state-active > * > * {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media (max-width: 1023px) {
  .navigation-main__toggle-content {
    background: #074982;
    border-top: 1px solid #3670a1;
    margin-bottom: -1px;
  }
}

@media screen and (min-width: 1024px) {
  .navigation-main__wrp-2 {
    height: 54px;
    position: relative;
    z-index: 20;
  }

  .navigation-main__wrp-3 {
    -webkit-transition: opacity 270ms;
    -moz-transition: opacity 270ms;
    -o-transition: opacity 270ms;
    transition: opacity 270ms;
    opacity: 1;
  }

  .navigation-main__wrp-3.desktop-nav-openend {
    opacity: 0;
  }

  .navigation-main__section {
    float: left;
  }

  .navigation-main__section:after {
    content: "";
    display: table;
    clear: both;
  }

  .navigation-main-flyout-cover-head {
    background: #f3f3f3;
    height: 133px;
    left: 0;
    position: absolute;
    right: 0;
    top: -133px;
    z-index: 100;
  }

  .navigation-main-flyout-cover-strap {
    background: #003f75;
    height: 54px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
  }

  .navigation-main__section + .navigation-main__section li:first-child .nav-section__toggle:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -14px;
    width: 1px;
    background: #3670a1;
    z-index: 100;
  }

  .navigation-main__button-group {
    position: relative;
    z-index: 100;
  }

  .navigation-main__toggle-content {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
  }

  .navigation-main__section {
    display: block;
  }
}

.navigation-main__panel-login .login-form {
  display: block;
}

.navigation-main__panel-login .user-info {
  display: none;
}

.navigation-main_logged-in .navigation-main__panel-login .login-form {
  display: none;
}

.navigation-main_logged-in .navigation-main__panel-login .user-info {
  display: block;
}

.nav-section__toggle {
  -webkit-transition: all 90ms;
  -moz-transition: all 90ms;
  -o-transition: all 90ms;
  transition: all 90ms;
}

.nav-section__toggle:hover {
  cursor: pointer;
}

.nav-section__flyout {
  display: none;
}

@media (max-width: 1023px) {
  .nav-section a {
    background: rgba(8, 84, 148, 0);
    border-bottom: 1px solid #3670a1;
    color: #fff;
    display: block;
    padding: 14px 43px 12px 14px;
    position: relative;
    outline: 0 !important;
  }

  .nav-section a:hover, .nav-section a:focus {
    text-decoration: none;
  }

  .nav-section a:focus {
    background: #53a2e6 !important;
    color: #003f75 !important;
  }

  .nav-section a strong {
    display: block;
  }

  .nav-section a.mobile-toggle_state-active {
    background: #085494;
  }

  .nav-section__level-2 .mobile-toggle_state-active,
  .nav-section__level-2 .mobile-toggle_state-active + ul a {
    border-bottom-color: #7f9fba;
  }

  .nav-section__flyout {
    background: #085494;
    opacity: 1 !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
    width: 100% !important;
  }

  .nav-section__flyout a.link:before, .nav-section__flyout a.back-link:before, .nav-section__flyout .linklist > li > a:before {
    display: none;
  }

  .nav-section__flyout a.mobile-toggle_state-active {
    background: #1b72bc;
  }

  .nav-section__flyout ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
  }

  .nav-section__flyout ul li {
    width: 100%;
  }

  .nav-section__level-2 > .nav-section__title + ul {
    background: #1b72bc;
    display: none;
  }

  .nav-section__level-2 ul li a strong {
    font-weight: normal;
  }
}

@media screen and (min-width: 1024px) {
  .nav-section__toggle {
    background: rgba(20, 83, 137, 0);
    display: table-cell;
    height: 54px;
    font-size: 16px;
    padding: 0 16px 0;
    position: relative;
    z-index: 110;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none !important;
    vertical-align: middle;
  }

  .nav-section__toggle:hover, .nav-section__toggle:focus, .nav-section__toggle.nav-section__toggle_state-active {
    background: #145389;
    text-decoration: none;
  }

  .nav-section__toggle.nav-section__toggle_state-active {
    background: #fff;
    color: #003f75;
  }

  .nav-section__toggle > strong {
    font-weight: normal;
  }

  .nav-section__flyout {
    background: #ffffff;
    bottom: 0;
    display: block !important;
    left: 0;
    opacity: 0;
    padding: 16px 14px 14px 14px;
    position: absolute;
    right: 0;
    border-bottom: 2px solid #ddd;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: -webkit-transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000), opacity 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    -webkit-transition-delay: 0s, 90ms;
    -moz-transition: -moz-transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000), opacity 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 90ms;
    -o-transition: -o-transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000), opacity 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 90ms;
    transition: transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000), opacity 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 90ms;
    z-index: 90;
  }

  .nav-section__flyout ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .nav-section__flyout ul:after {
    content: "";
    display: table;
    clear: both;
  }

  .nav-section__toggle_state-active + .nav-section__flyout {
    opacity: 1;
    -webkit-transition: -webkit-transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000), opacity 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    -moz-transition: -moz-transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000), opacity 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    -o-transition: -o-transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000), opacity 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    transition: transform 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000), opacity 270ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  }

  .nav-section__toggle_state-active + .nav-section__flyout_transition-switch-in {
    -webkit-transition: -webkit-transform 0, opacity 270ms cubic-bezier(0.470, 0.000, 0.745, 0.715);
    -moz-transition: -moz-transform 0, opacity 270ms cubic-bezier(0.470, 0.000, 0.745, 0.715);
    -o-transition: -o-transform 0, opacity 270ms cubic-bezier(0.470, 0.000, 0.745, 0.715);
    transition: transform 0, opacity 270ms cubic-bezier(0.470, 0.000, 0.745, 0.715);
  }

  .nav-section__flyout_transition-switch-out {
    -webkit-transition: -webkit-transform 0 linear, opacity 135ms;
    -webkit-transition-delay: 270ms, 0s;
    -moz-transition: -moz-transform 0 linear 270ms, opacity 135ms;
    -o-transition: -o-transform 0 linear 270ms, opacity 135ms;
    transition: transform 0 linear 270ms, opacity 135ms;
  }

  .nav-section__flyout_animation-paused {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }

  .nav-section__level-2 {
    float: left;
    padding-left: 28px;
    padding-top: 5px;
    position: relative;
    width: 20%;
  }

  .nav-section__level-2_colspan-1 {
    width: 10%;
  }

  .nav-section__level-2_colspan-2 {
    width: 20%;
  }

  .nav-section__level-2_colspan-3 {
    width: 30%;
  }

  .nav-section__level-2_colspan-4 {
    width: 40%;
  }

  .nav-section__level-2_colspan-5 {
    width: 50%;
  }

  .nav-section__level-2_colspan-6 {
    width: 60%;
  }

  .nav-section__level-2_colspan-7 {
    width: 70%;
  }

  .nav-section__level-2_colspan-8 {
    width: 80%;
  }

  .nav-section__level-2_colspan-9 {
    width: 90%;
  }

  .nav-section__level-2_colspan-10 {
    width: 100%;
  }

  .nav-section__title {
    margin: 0 7px 22px;
  }

  .nav-section__title:after {
    border-bottom: 2px dotted #b2c5d6;
    content: "";
    display: block;
    left: 28px;
    margin-top: 10px;
    position: absolute;
    right: 0;
  }

  .nav-section__title_mobile-link {
    display: none;
  }

  .nav-section__level-2 > ul > li {
    margin-bottom: 9px;
  }

  .nav-section__level-2 > ul > li a {
    display: block;
    line-height: 1.15;
    padding-left: 21px;
  }
}

.nav-section__zielgruppen ul {
  float: right;
}

.nav-section__zielgruppen li {
  float: left;
  margin-right: 28px;
}

.bstrdchilds {
  float: left;
  clear: none !important;
}

.bstrdchilds a {
  display: block;
}

.nav-section__toggle:focus {
  background: inherit;
}

.nav-section__toggle.nav-section__toggle_state-active:focus {
  background: #fff;
}

.navigation-main__section + .navigation-main__section li:first-child .nav-section__toggle:before {
  left: 0;
}

.navigation-main__language-select {
  padding: 0 14px 0 16px;
  width: auto;
}

li.nav-section.nav-section__level-1.spacer {
  width: 1px !important;
  overflow: hidden !important;
  background-color: #3670A1 !important;
  height: 54px !important;
}

li.nav-section.nav-section__level-1.spacer span {
  width: 1px !important;
  overflow: hidden !important;
  background-color: #3670A1 !important;
  height: 54px !important;
  cursor: default !important;
}

.nav-section__level-2 .nav-section__title {
  font-weight: bold;
}

.noborder .nav-section__title:after {
  border: 0 none;
}

.navigation-main__section > ul > li > a {
  overflow: hidden;
}

.navigation-main__panel_state-active {
  padding: 0 14px;
}

.nav-section__level-2_colspan-25 {
  width: 25%;
}

.nav-section__level-1.navigation-main__button_active > a {
  background-color: #3670A1;
}

@media (max-width: 767px) {
  .nav-section__level-1 {
    height: auto;
  }

  .nav-section__level-1.spacer {
    display: none;
  }
}

.user-info {
  padding: 14px 0;
  background: #3670a1;
}

.user-info:after {
  content: "";
  display: table;
  clear: both;
}

.user-info__col-2 {
  padding-left: 0;
}

.user-info__col-2 span {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.user-info__col-2 i {
  font-size: 18px;
  font-weight: normal;
  line-height: 0;
  vertical-align: middle;
}

@media screen and (min-width: 768px) {
  .user-info__col-2 {
    padding-left: 14px;
  }

  .user-info__col-2 span {
    position: static;
  }

  .user-info__col-2 i {
    display: none;
  }
}

.user-info__userdata {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  font-size: 1em;
  line-height: 34px;
  padding-left: 44px;
}

.user-info__avatar {
  position: absolute;
  left: 0;
  width: 34px;
  height: 34px;
  overflow: hidden;
  text-align: center;
  background: #003f75;
}

.user-info__avatar i {
  color: #fff;
  font-size: 18px;
  line-height: 34px;
  font-weight: normal;
  vertical-align: middle;
}

.user-info__form-search, .user-info__form-submit {
  width: 100%;
}
