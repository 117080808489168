.info-teaser__item-group,
.news-teaser__item-group,
.teaser-group__wrapper {
  margin-left: -14px;
  margin-right: -14px;
}

.info-teaser__item-group:before,
.news-teaser__item-group:before,
.teaser-group__wrapper:before,
.info-teaser__item-group:after,
.news-teaser__item-group:after,
.teaser-group__wrapper:after {
  content: " ";
  display: table;
}

.info-teaser__item-group:after,
.news-teaser__item-group:after,
.teaser-group__wrapper:after {
  clear: both;
}

.news-teaser__item,
.info-teaser_items-2 .info-teaser__item,
.info-teaser_items-3 .info-teaser__item,
.info-teaser_items-4 .info-teaser__item,
.teaser-group__item,
.info-teaser__item {
  position: relative;
  min-height: 1px;
  padding-left: 14px;
  padding-right: 14px;
}

.info-teaser__item,
.news-teaser__item,
.teaser-group__item {
  width: 100%;
}

@media (min-width: 768px) {
  .news-teaser__item,
  .info-teaser_items-2 .info-teaser__item,
  .info-teaser_items-3 .info-teaser__item,
  .info-teaser_items-4 .info-teaser__item,
  .teaser-group__item {
    float: left;
  }

  .news-teaser__item {
    width: 25%;
  }

  .info-teaser_items-2 .info-teaser__item,
  .info-teaser_items-4 .info-teaser__item {
    width: 50%;
  }

  .info-teaser_items-3 .info-teaser__item,
  .teaser-group__item {
    width: 33.33333333333333%;
  }
}

@media (min-width: 1024px) {
  .info-teaser_items-4 .info-teaser__item,
  .teaser-group__item {
    float: left;
  }

  .info-teaser_items-4 .info-teaser__item {
    width: 25%;
  }

  .teaser-group__item {
    width: 33.33333333333333%;
  }
}

.info-teaser {
  margin-bottom: 28px;
}

.info-teaser:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 768px) {
  .info-teaser {
    margin-bottom: 0;
  }
}

.info-teaser__item-group {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.info-teaser__item {
  margin-bottom: 7px;
}

.info-teaser_items-2 .info-teaser__item:nth-child(2n+1) {
  clear: both;
}

.info-teaser_items-3 .info-teaser__item:nth-child(3n+1) {
  clear: both;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .info-teaser_items-4 .info-teaser__item:nth-child(2n+1) {
    clear: both;
  }
}

@media screen and (min-width: 1024px) {
  .info-teaser_items-4 .info-teaser__item:nth-child(4n+1) {
    clear: both;
  }
}

@media print {
  .info-teaser__item {
    height: auto !important;
    margin-bottom: 7px;
  }
}

.info-teaser__item-body {
  background: #fff;
  height: 100%;
}

.info-teaser__item-header {
  border-bottom: 2px solid #f3f3f3;
  padding: 14px;
}

.info-teaser__item-title {
  margin: 0;
  color: #878e8a;
  font-weight: normal;
}

.info-teaser__item-content {
  padding: 14px;
}

.info-teaser__item-header {
  border-bottom: 2px solid #e9eaed;
}

.info-teaser__item-header h1,
.info-teaser__item-header h2,
.info-teaser__item-header h3 {
  font-size: 1.4em;
  line-height: 1.2;
  margin: 0;
  color: #000;
  font-weight: normal;
}

.info-teaser__item-header h1 a,
.info-teaser__item-header h2 a,
.info-teaser__item-header h3 a {
  color: #0079AD;
}

@media screen and (min-width: 768px) and (max-width: 1023px) and (min-width: 768px) {
  .layout-context .col-sm-3 .info-teaser__item,
  .layout-leftnav .col-sm-3 .info-teaser__item {
    min-height: 2em;
  }
}

@media (max-width: 767px) {
  .info-teaser__item {
    min-height: 2em;
  }
}

.teaser-group {
  margin-bottom: 28px;
}

.teaser-group__headline {
  min-height: 1.2em;
}

.teaser-group__wrapper {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.teaser-group__item {
  margin-bottom: 3px;
}

@media (max-width: 1023px) {
  .teaser-group__item {
    width: 100%;
    margin-bottom: 14px;
  }
}

.teaser-group__item-body {
  background: #fff;
}

.teaser-group__item-body:after {
  content: "";
  display: table;
  clear: both;
}

.teaser-group__item_extra .teaser-group__item-body {
  background: #d3e3f0;
}

.teaser-group__item-image {
  float: left;
  width: 100px;
  overflow: hidden;
  padding: 14px 10px;
}

.teaser-group__item-image img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 1024px) {
  .teaser-group__item-image {
    float: none;
    width: auto;
    height: 168px;
    padding: 0;
  }

  .teaser-group__item-image img {
    display: inline-block;
    width: auto;
    height: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .teaser-group__item-image-align {
    margin: 0 -50px;
    height: 100%;
    text-align: center;
  }
}

.teaser-group__item-text {
  padding: 10px 5px 5px 0;
  overflow: hidden
}

.teaser-group__item-text h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 0.2em;
  color: #1D1D1B;
}

.teaser-group__item-text p {
  margin-bottom: 0.5em;
  color: #1D1D1B;
}

@media screen and (min-width: 1024px) {
  .teaser-group__item-text {
    padding: 14px 10px 5px 14px;
  }
}

.teaser-group__item-body img {
  width: 100%;
}

#content .teaser-group__item {
  width: auto;
  padding-left: 0;
  padding-right: 0;
}

.teaser-group__item h1,
.teaser-group__item h2,
.teaser-group__item h3,
.teaser-group__item h5,
.teaser-group__item h6 {
  color: #1D1D1B;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 0.4em;
  margin-top: 0.6em;
}

.teaser-group__item_textpic .teaser-group__item-text h1,
.teaser-group__item_textpic .teaser-group__item-text h2,
.teaser-group__item_textpic .teaser-group__item-text h3,
.teaser-group__item_textpic .teaser-group__item-text h5,
.teaser-group__item_textpic .teaser-group__item-text h6 {
  margin-top: 0;
}

#content .teaser-group__item_textpic {
  width: 100%;
}

.list .teaser-group__item-text {
  min-height: 1em;
}

.list .teaser-group__item-text h3 {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 768px) and (max-width: 1023px) and (min-width: 768px) {
  .teaser-group__item-body img {
    float: left;
    padding: 14px 0 0 14px;
  }

  .teaser-group__item-body img {
    float: left;
    padding: 0;
  }

  .teaser-group__item-text {
    clear: both;
    padding: 14px;
  }
}

@media (max-width: 767px) {
  .teaser-group__item-body img {
    float: left;
    padding: 0;
  }

  .teaser-group__item-text {
    clear: both;
    padding: 14px;
  }
}

.sidebar-teaser + .sidebar-teaser {
  margin-top: -25px;
}

.sidebar-teaser__image {
  float: left;
  width: 100px;
  overflow: hidden;
  padding: 14px 0 14px 10px;
}

.sidebar-teaser__image img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 768px) {
  .sidebar-teaser__image {
    float: none;
    width: auto;
    height: 168px;
    padding: 0;
  }

  .sidebar-teaser__image img {
    display: inline-block;
    width: auto;
    height: 100%;
  }
}

@media screen and (min-width: 768px) {
  .sidebar-teaser__image-align {
    margin: 0 -50px;
    height: 100%;
    text-align: center;
  }
}

.sidebar-teaser__text h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 0.2em;
  color: #1D1D1B;
}

.sidebar-teaser__text p {
  margin-bottom: 0.5em;
  color: #1D1D1B;
}
